import React, { Component } from 'react';
import { SatCoreRegister, StateIconComponent } from 'sat-core';
import { observer } from 'mobx-react';

import iconNoState from '../img/states/icon-no-state.svg';
import icon_arizona from '../img/states/icon-arizona.svg';
import icon_california from '../img/states/icon-california.svg';
import icon_florida from '../img/states/icon-florida.svg';
import icon_louisiana from '../img/states/icon-louisiana.svg';
import icon_maryland from '../img/states/icon-maryland.svg';
import icon_massachusetts from '../img/states/icon-mass.svg';
import icon_minnesota from '../img/states/icon-minnesota.svg';
import icon_newjersey from '../img/states/icon-newjersey.svg';
import icon_newyork from '../img/states/icon-newyork.svg';
import icon_southcarolina from '../img/states/icon-southcarolina.svg';

export default
@observer
class StateIconComponentOverride extends Component {
  selectStateIcon = (institutionState) => {
    const state = institutionState.toUpperCase();
    let stateIcon = null;
    switch (state) {
      case 'AZ':
        stateIcon = icon_arizona;
        break;
      case 'CA':
        stateIcon = icon_california;
        break;
      case 'FL':
        stateIcon = icon_florida;
        break;
      case 'LA':
        stateIcon = icon_louisiana;
        break;
      case 'MD':
        stateIcon = icon_maryland;
        break;
      case 'MA':
        stateIcon = icon_massachusetts;
        break;
      case 'MN':
        stateIcon = icon_minnesota;
        break;
      case 'NJ':
        stateIcon = icon_newjersey;
        break;
      case 'NY':
        stateIcon = icon_newyork;
        break;
      case 'SC':
        stateIcon = icon_southcarolina;
        break;
      default:
        return iconNoState;
    }
    return stateIcon;
  };

  render() {
    const { institutionState } = this.props;
    return (
      <>
        <StateIconComponent institutionState={institutionState} selectStateIcon={this.selectStateIcon} />
      </>
    );
  }
}

SatCoreRegister('StateIconComponent', StateIconComponentOverride);
