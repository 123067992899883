import { action, computed, extendObservable, has, makeObservable, observable, set } from 'mobx';

export class PlayerManager {
  @observable playerState = {
    lessonPlayerShowing: false,
    learnosityPlayerShowing: false,
    fileViewerShowing: false,
    contentUrl: null,
    contentName: null,
    isFlowpaper: false,
    assignmentId: null,
    contentItemId: null,
    previewContentType: null,
    previewContentItemId: null,
    resourceName: null,
    instruction: null,
    contentImageUrl: null
  };

  constructor() {
    makeObservable(this);
  }

  @action setState = (newPlayerState) => {
    for (const [key, value] of Object.entries(newPlayerState)) {
      if (has(this.playerState, key)) {
        set(this.playerState, key, value);
      } else {
        const prop = {};
        prop[key] = value;
        extendObservable(this.playerState, prop);
      }
    }
  }

  @computed get playerShowing() {
    return (this.playerState.lessonPlayerShowing ||
      this.playerState.learnosityPlayerShowing ||
      this.playerState.fileViewerShowing);
  }

  @computed get lessonPlayerShowing() {
    return this.playerState.lessonPlayerShowing;
  }

  @computed get learnosityPlayerShowing() {
    return this.playerState.learnosityPlayerShowing;
  }

  @computed get fileViewerShowing() {
    return this.playerState.fileViewerShowing;
  }
}

export default new PlayerManager();
