import React from 'react';
import { CourseListBanner, SatCoreRegister } from 'sat-core';

const CourseListBannerOverride = (props) => (
  <CourseListBanner
    {...props}
    showBreadCrumbs={props.showBreadCrumbs && 'top'}
    showBreadCrumbsDropdown={false} />
);

SatCoreRegister('CourseListBanner', CourseListBannerOverride);
