import React from 'react';
import { Icon } from 'semantic-ui-react';
import '../css/Footer.less';

export default function Footer(props) {
  return (
    <>
      <div className='footer-panel'>
        <div className='privacy-links'>
          <div className='links'>
            <a className='terms' href='https://empoweredschools.org/emPowered-Website-Terms-of-Use-D4.html' rel='noreferrer noopener' target='_blank'>Terms of Service</a>
            <a className='privacy' href='https://empoweredschools.org/EmPowered-Privacy-Policy-D4-.html' rel='noreferrer noopener' target='_blank'>Privacy Policy</a>
          </div>
          <div className='copy-rights'>
            <span>Copyright 2020</span>
            {' '}
            <Icon name='copyright outline' />
            {' '}
            <span>Alliance to Save Energy. All rights reserved.</span>
          </div>
        </div>
        {(props.image !== null) ?
          <div className='institution-logo'><img alt='' className='institution-logo' src={props.image} /></div> :
          <div className='institution-logo' />}
      </div>
    </>
  );
}
