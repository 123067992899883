import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { SatCoreComponent } from 'sat-core';
import PlayerService from '../services/PlayerService';

export default
@inject('playerManager', 'userManager')
@observer
class PlayerComponent extends Component {
  constructor(props) {
    super(props);
    this.LearnosityPlayerModal = SatCoreComponent('LearnosityPlayerModal');
    this.FullscreenModal = SatCoreComponent('FullscreenModal');
    this.FileViewerModal = SatCoreComponent('FileViewerModal');
  }

  hideIframe = () => {
    PlayerService.hideIframe();
  }

  hideModal = () => {
    PlayerService.setState({ learnosityPlayerShowing: false, fileViewerShowing: false, instruction: '' });
  }

  renderLessonPlayer = () => {
    const { playerManager, userManager } = this.props;
    const { FullscreenModal } = this;
    if (this.props.renderLessonPlayer !== undefined) {
      return this.props.renderLessonPlayer();
    }

    return (
      <div className='course-content'>
        <FullscreenModal
          closeIframeCallback={this.hideIframe}
          contentImageUrl={playerManager.playerState.contentImageUrl}
          contentName={playerManager.playerState.contentName}
          instruction={playerManager.playerState.instruction}
          isTeacher={userManager.isTeacher}
          page='lesson-player'
          url={playerManager.playerState.contentUrl} />
      </div>
    );
  }

  renderFileViewer = () => {
    const { playerManager, userManager } = this.props;
    const { FileViewerModal } = this;

    if (this.props.renderFileViewer !== undefined) {
      return this.props.renderFileViewer();
    }

    return (
      <div className='course-content'>
        <FileViewerModal
          closeModalCallback={this.hideModal}
          contentImageUrl={playerManager.playerState.contentImageUrl}
          contentName={playerManager.playerState.contentName}
          contentType={playerManager.playerState.previewContentType}
          instruction={playerManager.playerState.instruction}
          isFlowpaper={playerManager.playerState.isFlowpaper}
          isTeacher={userManager.isTeacher}
          page='file-viewer'
          resourceName={playerManager.playerState.resourceName}
          url={playerManager.playerState.contentUrl} />
      </div>
    );
  }

  renderLearnosityPlayer = () => {
    const { playerManager, userManager } = this.props;
    const { LearnosityPlayerModal } = this;

    if (this.props.renderLearnosityPlayer !== undefined) {
      return this.props.renderLearnosityPlayer();
    }

    return (
      <div className='course-content'>
        <LearnosityPlayerModal
          assignmentId={playerManager.playerState.assignmentId}
          closeModalCallback={this.hideModal}
          contentImageUrl={playerManager.playerState.contentImageUrl}
          contentItemId={playerManager.playerState.contentItemId}
          contentName={playerManager.playerState.contentName}
          instruction={playerManager.playerState.instruction}
          isTeacher={userManager.isTeacher}
          page='learnosity-player'
          resourceName={playerManager.playerState.resourceName} />
      </div>
    );
  }

  render() {
    const { playerManager } = this.props;

    return (
      <>
        {(playerManager.lessonPlayerShowing) ?
          this.renderLessonPlayer() :
          (playerManager.learnosityPlayerShowing) ?
            this.renderLearnosityPlayer() :
            (playerManager.fileViewerShowing) ?
              this.renderFileViewer() : null}
      </>
    );
  }
}
