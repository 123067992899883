let ecmsBaseUrl;
let publisherBaseUrl;
let publisherSatelliteCode;

const hostname = window && window.location && window.location.hostname;

if (hostname === 'empowered.content2classroom.com') {
  ecmsBaseUrl = 'https://empowered.content2classroom.com';
  publisherBaseUrl = 'https://publisher.content2classroom.com';
  publisherSatelliteCode = 'EMPOWER_SATELLITE';
} else if (hostname === 'staging-empowered.content2classroom.com') {
  ecmsBaseUrl = 'https://staging-empowered.content2classroom.com';
  publisherBaseUrl = 'https://staging-publisher.content2classroom.com';
  publisherSatelliteCode = 'EMPOWER_SATELLITE';
} else if (hostname === 'qaprod-empowered.content2classroom.com') {
  ecmsBaseUrl = 'https://qaprod-empowered.content2classroom.com';
  publisherBaseUrl = 'https://qaprod-publisher.content2classroom.com';
  publisherSatelliteCode = 'EMPOWER_SATELLITE';
} else if (hostname === 'ast-empowered.content2classroom.com') {
  ecmsBaseUrl = 'https://ast-empowered.content2classroom.com';
  publisherBaseUrl = 'https://ast-publisher.content2classroom.com';
  publisherSatelliteCode = 'EMPOWER_SATELLITE';
} else if (hostname === 'qa-empowered.content2classroom.com') {
  ecmsBaseUrl = 'https://qa-empowered.content2classroom.com';
  publisherBaseUrl = 'https://qa-publisher.content2classroom.com';
  publisherSatelliteCode = 'EMPOWER_SATELLITE';
} else if (hostname === 'dev.content2classroom.com') {
  ecmsBaseUrl = 'https://qa-empowered.content2classroom.com';
  publisherBaseUrl = 'https://qa-publisher.content2classroom.com';
  publisherSatelliteCode = 'EMPOWER_SATELLITE';
  // publisherSatelliteCode = 'EVOTEXT_DEMO';
} else {
  if (process.env.REACT_APP_URI_SCHEME === 'https') {
    ecmsBaseUrl = 'https://localhost:8080';
    publisherBaseUrl = 'https://localhost:7777';
  } else {
    ecmsBaseUrl = 'http://localhost:8080';
    publisherBaseUrl = 'http://localhost:7777';
  }
  publisherSatelliteCode = 'EMPOWER_SATELLITE';
}

export const API_ROOT = `${ecmsBaseUrl}`;
export const PUBLISHER_ROOT = `${publisherBaseUrl}`;
export const PUBLISHER_SATELLITE_CODE = `${publisherSatelliteCode}`;
export const LESSON_ROOT = null;
