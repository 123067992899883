import { action, computed, extendObservable, has, makeObservable, observable, set } from 'mobx';
import { Auth } from 'sat-core';

const PROGRESS_ENDPOINTS = {
  GET_LPL_STATES: '/api/viewInstitutionStates',
  GET_LPL_INSTITUTIONS: '/api/viewPublisherSchoolsByState',
  GET_LPL_COURSES: '/api/searchPublishedContentItemsByProducts'
};

export class FilterManager {
  @observable currentFilter = {
    stateId: '',
    institutionId: '',
    courseContentItemIds: [],
    search: ''
  }

  @observable stateArray = [];

  @observable institutionMap = new Map();

  @observable courseContentArray = [];

  constructor() {
    makeObservable(this);
  }

  @action clearAll=() => {
    this.currentFilter = {
      stateId: '',
      institutionId: '',
      courseContentItemIds: [],
      search: ''
    };
  }

  @action setFilter = (filter) => {
    for (const [key, value] of Object.entries(filter)) {
      if (has(this.currentFilter, key)) {
        set(this.currentFilter, key, value);
      } else {
        const prop = {};
        prop[key] = value;
        extendObservable(this.currentFilter, prop);
      }
    }
  }

  @action addState = (state) => {
    this.stateArray.push(state);
  }

  @action addInstitutions = (stateId, institution) => {
    this.institutionMap.set(stateId, institution);
  }

  @action addCourseContentItem = (course) => {
    this.courseContentArray.push(course);
  }

  @computed get institutionArray() {
    const array = this.institutionMap.get(this.currentFilter.stateId);

    if (array === null || array === undefined) {
      return [];
    }
    return array;
  }

  fetchFilters = async () => {
    try {
      let response = null;
      if (this.courseContentArray.length === 0) {
        response = await Auth.fetch(`${Auth.ecms}${PROGRESS_ENDPOINTS.GET_LPL_COURSES}`, {
          method: 'GET'
        });

        if (response && response.status === 'SUCCESS') {
          response.contentItems.forEach((contentItem) => {
            this.addCourseContentItem(contentItem);
          });
        }
      }

      if (this.stateArray.length === 0) {
        response = await Auth.fetch(`${Auth.ecms}${PROGRESS_ENDPOINTS.GET_LPL_STATES}`, {
          method: 'GET'
        });

        if (response && response.status === 'SUCCESS') {
          response.data.forEach((state) => {
            this.addState(state);
          });
        }
      }

      if (this.currentFilter.stateId !== null && this.currentFilter.stateId !== '') {
        const institutionArray = this.institutionMap.get(this.currentFilter.stateId);
        if (institutionArray === null || institutionArray === undefined || institutionArray.length === 0) {
          response = await Auth.fetch(`${Auth.ecms}${PROGRESS_ENDPOINTS.GET_LPL_INSTITUTIONS}?stateId=${this.currentFilter.stateId}`, {
            method: 'GET'
          });

          if (response && response.status === 'SUCCESS') {
            this.addInstitutions(this.currentFilter.stateId, response.institutions);
          }
        }
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}

export default new FilterManager();
