import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { CONTENT_MODE, Modal, ModalBanner, RichTextEditor } from 'sat-core';
import { Button } from 'semantic-ui-react';

import '../css/ApprovalDialog.less';
import PlayerService from '../services/PlayerService';

export const APPROVAL = {
  NO: 'no',
  YES: 'yes',
  OFF: 'off'
};

export default
@inject('assignmentManager', 'progressManager')
@observer
class ApprovalDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorValue: '',
      approval: props.defaultValue || APPROVAL.YES,
      errorMessage: '',
      assignment: null
    };
  }

  async componentDidMount() {
    await this.fetchActivity();
  }

  callCloseApproval = () => {
    const { closeApproval } = this.props;
    this.setState({ errorMessage: '' });
    closeApproval();
  }

  setApprove =(value) => {
    this.setState({ approval: value });
  }

  commitApproval = async () => {
    const { progressManager, assignment, closeApproval, onApprove, onDisApprove } = this.props;
    const { approvalStatusMessage } = progressManager;
    const { approval, editorValue } = this.state;
    let approvalSucceeded = false;
    if (approval === APPROVAL.YES) {
      approvalSucceeded = await progressManager.approveActivity(assignment, editorValue);
      if (approvalSucceeded) {
        this.setState({ editorValue: '', approval: APPROVAL.OFF, errorMessage: '' });
        onApprove?.();
        closeApproval();
      } else {
        this.setState({ errorMessage: progressManager.approvalStatusMessage });
      }
    } else {
      approvalSucceeded = await progressManager.disApproveActivity(assignment, editorValue);
      if (approvalSucceeded) {
        this.setState({ editorValue: '', approval: APPROVAL.OFF, errorMessage: '' });
        onDisApprove?.();
        closeApproval();
      } else {
        this.setState({ errorMessage: approvalStatusMessage });
      }
    }
  }

  editorChange = (value) => {
    this.setState({ editorValue: value });
  };

  fetchActivity = async () => {
    const { assignment: activity, assignmentManager } = this.props;
    if (activity.entityTypeId && activity.activityInstanceUserId) {
      this.setState({assignment: activity});
      return activity;
    }
    const [assignment, assignmentInstance] = await Promise.all([
      assignmentManager.fetchActivity(activity.id),
      assignmentManager.fetchSubmittedActivityInstance(activity.id)
    ]);
    if (assignment && assignmentInstance) {
      assignment.activityInstanceUserId = assignmentInstance.userId;
      this.setState({assignment});
      return assignment;
    }
  }

  markActivityAsReviewed = async() => {
    const { assignment: activity, assignmentManager } = this.props;
    const { assignment } = this.state;
    !assignment?.scoresReleased && await assignmentManager.markActivityAsReviewed(activity.id);
  }

  openReview = async () => {
    const assignment = await this.fetchActivity();
    await this.markActivityAsReviewed();
    if (!assignment) {
      return;
    }
    const assignmentInstanceDummy = {
      id: assignment.id,
      activityId: assignment.id,
      userId: assignment.activityInstanceUserId
    };
    //this.setState({ approval: APPROVAL.OFF });
    await PlayerService.launchPlayerFromAssignment(assignment, assignmentInstanceDummy, CONTENT_MODE.REVIEW);
  }

  render() {
    const { assignment: { name: title = '' } = {}, openApproval, closeApproval, label = 'Grade:' } = this.props;
    const { assignment, editorValue, approval, errorMessage } = this.state;

    return (
      <Modal className='send-modal approval' onClose={closeApproval} open={openApproval} size='small'>
        <ModalBanner label={label} onClose={closeApproval} title={title} />

        <Modal.Content>
          <div className='toggle-buttons'>
            <Button basic onClick={this.openReview} primary type='button'>
              Review Activity
              {assignment?.scoresReleased && <span className='dot' />}
            </Button>
            <Button basic className={(approval === APPROVAL.NO) ? 'solid' : ''} onClick={() => this.setApprove(APPROVAL.NO)} primary
              type='button'>Don&apos;t Approve
            </Button>
            <Button basic className={(approval === APPROVAL.YES) ? 'solid' : ''} onClick={() => this.setApprove(APPROVAL.YES)} primary
              type='button'>Approve
            </Button>
          </div>
          <div className={(approval === APPROVAL.OFF) ? 'approval_ui_hide' : ''}>
            {!errorMessage && (
              <>
                <div className='comments'>Comments:</div>
                <RichTextEditor maxCharacters={500} onChange={this.editorChange} value={editorValue} />
              </>
            )}
            {errorMessage && (
              <>
                <div className='error'>{errorMessage}</div>
              </>
            )}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button basic className={(approval === APPROVAL.OFF) ? 'approval_ui_hide' : 'cancel'} onClick={this.callCloseApproval} primary
            type='button'>Cancel
          </Button>
          {!errorMessage &&
            <Button className={(approval === APPROVAL.OFF) ? 'approval_ui_hide' : (approval === APPROVAL.YES) ? 'approve' : ''} onClick={this.commitApproval} primary type='button'>{(approval === APPROVAL.YES) ? 'Approve' : 'Don\'t Approve'}</Button>}
        </Modal.Actions>
      </Modal>
    );
  }
}
