import { reaction } from 'mobx';
import { CONTENT_ITEM_TYPES, VIEW_SELECTION, classroomManager, navigationManager, userManager } from 'sat-core';
import backgroundImageManager, { BACKGROUND_CLASSES } from '../managers/BackgroundImageManager';

export default class BackgroundService {
  static disposer = null;

  static initBackgroundImageService() {
    if (BackgroundService.disposer === null) {
      BackgroundService.disposer = reaction(() => navigationManager.viewSelection,
        (selection) => {
          BackgroundService.updateBackgroundClass(selection);
        },
        { fireImmediately: true });
    }
  }

  static updateBackgroundClass(selection) {
    BackgroundService.setTheBackground(selection);
  }

  static setTheBackground(selection) {
    const now = new Date();
    const currentMonth = now.getMonth() + 1;
    const currentDay = now.getDate();
    let className = '';
    if (currentMonth === 9 || (currentMonth === 10 && currentDay < 19)) {
      className = BACKGROUND_CLASSES.SEPTEMBER;
    } else if ((currentMonth === 10 && currentDay >= 19) || (currentMonth === 11 && currentDay < 16)) {
      className = BACKGROUND_CLASSES.OCTOBER;
    } else if ((currentMonth === 11 && currentDay >= 16) || (currentMonth === 12 && currentDay < 14)) {
      className = BACKGROUND_CLASSES.NOVEMBER;
    } else if ((currentMonth === 12 && currentDay >= 14) || (currentMonth === 1 && currentDay < 4)) {
      className = BACKGROUND_CLASSES.DECEMBER;
    } else if ((currentMonth === 1 && currentDay >= 4) || (currentMonth === 2 && currentDay < 3)) {
      className = BACKGROUND_CLASSES.JANUARY;
    } else if ((currentMonth === 2 && currentDay >= 3) || (currentMonth === 3 && currentDay < 3)) {
      className = BACKGROUND_CLASSES.FEBRUARY;
    } else if ((currentMonth === 3) && (currentDay >= 3 && currentDay < 29)) {
      className = BACKGROUND_CLASSES.MARCH;
    } else if ((currentMonth === 3 && currentDay >= 29) || (currentMonth === 4) || (currentMonth === 5 && currentDay < 3)) {
      className = BACKGROUND_CLASSES.APRIL;
    } else if ((currentMonth === 5 && currentDay >= 3) || (currentMonth === 6 && currentDay < 14)) {
      className = BACKGROUND_CLASSES.MAY;
    } else if ((currentMonth === 6 && currentDay >= 14) || (currentMonth >= 7 && currentMonth < 9)) {
      className = BACKGROUND_CLASSES.JUNE;
    }
    if (selection === VIEW_SELECTION.LOGIN) {
      className = `${BACKGROUND_CLASSES.LOGIN} ${className}`;
    }

    if (selection === VIEW_SELECTION.BOOK) {
      if (backgroundImageManager.currentImage !== null) {
        backgroundImageManager.setCurrentImageClass('');
      } else {
        backgroundImageManager.setCurrentImageClass(className);
        backgroundImageManager.setCurrentImage(null);
      }
    } else {
      backgroundImageManager.setCurrentImageClass(className);
      backgroundImageManager.setCurrentImage(null);
    }

    // this should go in another service but seems wasteful for one task
    if (selection === VIEW_SELECTION.DASHBOARD && userManager.isTeacher) {
      classroomManager.currentClassroomId = null;
    }
  }

  static setCurrentImage = (image) => {
    backgroundImageManager.setCurrentImage(image);
  }

  static sectionImageIsNull = () => !backgroundImageManager.currentImage

  static getBackgroundImage = async (elementId) => {
    const image = await backgroundImageManager.getSectionImage(elementId);
    if (image !== null) {
      BackgroundService.setCurrentImage(CONTENT_ITEM_TYPES.getContentIdImageUrl(image));
    }
  }

  static cleanUp() {
    if (BackgroundService.disposer !== null && BackgroundService.disposer !== undefined) {
      BackgroundService.disposer();
      BackgroundService.disposer = null;
    }
  }
}
