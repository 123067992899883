// typical imports
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import {
  Auth, COURSE_ENDPOINTS, InitService, appManager, assignmentManager,
  classroomManager, contentItemManager, contentManager, courseManager,
  dialogManager, gradebookManager, i18nAddSatellite, navigationManager,
  userManager
} from 'sat-core';
import * as serviceWorker from './serviceWorker';
import { API_ROOT, PUBLISHER_ROOT, PUBLISHER_SATELLITE_CODE } from './api-config';
///

// custom files for this sat
import backgroundImageManager from './managers/BackgroundImageManager';
import playerManager from './managers/PlayerManager';
import filterManager from './managers/FilterManager';
import progressManager from './managers/ProgressManager';
import BackgroundService from './services/BackgroundService';
import InstitutionLogoService from './services/InstitutionLogoService';
import reportsManager from './managers/ReportsManager';
/// ///

// do all overrides here
import logo from './img/Logo.svg';
import App from './views/AppOverride';
import './views/ClassDetailOverride';
import './views/CourseLayoutOverride';
import './views/LoginOverride';
import './views/MainViewOverride';
import './views/ProgressView';
import './views/reports/LPLReportsView';
import './views/reports/LPLClassPerformanceReport';
import './views/ClassRosterOverride';
import './views/ProfileOverride';
import './views/ChangePasswordOverride';
import './components/GradebookTable';
import './components/AssignmentFilter';
import './components/AssignmentCard';
import './components/StateIconComponentOverride';
import './components/CourseListBannerOverride';
import './components/dialogs/AddCourseToClassModal';
import './components/dialogs/AddClassModalOverride';
import './views/ClassSettingsOverride';
import './services/CourseService';
import './services/NavigationService';
import './services/ImageService';
import './services/InitService';
import './services/ClassroomService';
import './views/Register';
import 'semantic-ui-less/semantic.less';
import './index.less';
import empoweredTranslations from './empoweredTranslations';

i18nAddSatellite('emp', empoweredTranslations);

// for this satellite the teacher and student use the same custom course element endpoint.
COURSE_ENDPOINTS.GET_COURSE_ELEMENTS_STUDENT = '/api/empoweredViewCourseResourceElementsByParent';
COURSE_ENDPOINTS.GET_COURSE_ELEMENTS_TEACHER = '/api/empoweredViewCourseResourceElementsByParent';

InitService.doLogoutCleanUp = function (strOfItemsToPreserve = '') {
  InitService.logoutCleanUp(strOfItemsToPreserve);
  BackgroundService.cleanUp();
  InstitutionLogoService.cleanUp();
  progressManager.clearAll();
  filterManager.clearAll();
  reportsManager.clearAll();
};
/// // end overrides

// init build props
Auth.init(API_ROOT, PUBLISHER_ROOT, PUBLISHER_SATELLITE_CODE, logo);
appManager.init({ classWorkspaceFeature: true });

classroomManager.setAllowStudentDataMigrate(false);
classroomManager.setEnabledStudentsOnly(false);
classroomManager.setIncludeGroups(false);
contentItemManager.setPreventStudentWorkspaceOnRenameOption(false);
contentItemManager.setPreventStudentWorkspaceOnReplaceOption(false);
gradebookManager.setUseSubmitButton(false);
userManager.setAllowSwitchRole(false);

// launch app
ReactDOM.render(
  <Provider
    assignmentManager={assignmentManager}
    backgroundImageManager={backgroundImageManager}
    classroomManager={classroomManager}
    contentManager={contentManager}
    courseManager={courseManager}
    dialogManager={dialogManager}
    filterManager={filterManager}
    gradeBookManager={gradebookManager}
    navigationManager={navigationManager}
    playerManager={playerManager}
    progressManager={progressManager}
    reportsManager={reportsManager}
    userManager={userManager}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
