import {
  CourseService, ELEMENT_TYPE, NavigationService,
  PATH_TYPES, classroomManager, courseManager, userManager
} from 'sat-core';
import BackgroundService from './BackgroundService';

NavigationService.handleCourseElementClick = (courseElement, history) => {
  const { currentCourseId } = courseManager;
  const { currentClassroomId } = classroomManager;

  if (ELEMENT_TYPE.isBranchType((courseElement))) {
    NavigationService.navigateToPath({
      currentClassroomId,
      currentCourseId,
      currentElementId: courseElement.elementId,
      name: courseElement.name,
      parentElementId: courseElement.parentElementId,
      treeNavigationFunction: NavigationService.treeNavigationFunction,
      type: PATH_TYPES.TREE
    }, history, courseManager, userManager.isStudent, classroomManager);
  }
};

NavigationService.handleViewBenchmark = (currentCourseId, currentElementId, name, history) => {
  const { currentClassroomId } = classroomManager;
  courseManager.setCurrentCourseId(currentCourseId);
  courseManager.setCurrentElementId(currentElementId);

  NavigationService.navigateToPath({
    currentClassroomId,
    currentCourseId,
    currentElementId,
    name,
    parentElementId: '',
    treeNavigationFunction: NavigationService.treeNavigationFunction,
    type: PATH_TYPES.TREE
  }, history, courseManager, userManager.isStudent, classroomManager);
};

const oldNavigateToPath = NavigationService.navigateToPath;

NavigationService.navigateToPath = async (path, history, courseManager, isStudent = false, classroomManager) => {
  BackgroundService.setCurrentImage(null);
  oldNavigateToPath(path, history, courseManager, isStudent, classroomManager);
};

NavigationService.treeNavigationFunction = async (currentCourseId, currentClassroomId, currentElementId) => {
  courseManager.setTreeLoading(true);
  courseManager.setCurrentElementId(currentElementId);
  courseManager.setCurrentCourseId(currentCourseId);
  classroomManager.setCurrentClassroomId(currentClassroomId);
  const elements = await CourseService.initCourseData(false, true, true);
  return elements;
};
