import React, { Component, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Container, Header, Image, Loader, Popup, Table } from 'semantic-ui-react';
import { DIALOG_NAMES, PATH_TYPES, SatCoreComponent, SatCoreRegister, VIEW_SELECTION, navigationManager } from 'sat-core';
import ReactHtmlParser from 'react-html-parser';
import { merge, set } from 'lodash';

import classNames from 'classnames';

import ApprovalDialog, { APPROVAL } from '../../components/ApprovalDialog';
import PlayerComponent from '../../components/PlayerComponent';

import iconNotComplete from '../../img/icon-not-complete.svg';
import iconComplete from '../../img/icon-complete.svg';
import iconLightbulb from '../../img/icon-lightbulb.svg';
import iconDownload from '../../img/icon-download.svg';

import '../../css/LPLClassPerformanceReport.less';

const MagicResizeComponent = () => {
  useEffect(() => {
    // Call the reports manager to fetch the report data

    const firstHeader = document.getElementsByClassName('freeze-me one');
    const districtHeader = document.getElementsByClassName('district two');
    const schoolHeader = document.getElementsByClassName('school three');

    if (firstHeader && firstHeader.length &&
      districtHeader && districtHeader.length > 0 &&
      schoolHeader && schoolHeader.length > 0) {
      const bWidth = firstHeader[0].getBoundingClientRect().width;
      const dWidth = districtHeader[0].getBoundingClientRect().width;
      const dOffset = dWidth + bWidth;

      for (let i = 0; i < districtHeader.length; i++) {
        districtHeader[i].setAttribute('style', `left:${bWidth}px`);
      }

      for (let x = 0; x < schoolHeader.length; x++) {
        schoolHeader[x].setAttribute('style', `left:${dOffset}px`);
      }
      const districtTH = document.getElementsByClassName('header-column district');
      const schoolTH = document.getElementsByClassName('header-column school');

      if (districtTH && schoolTH && districtTH.length > 0 && schoolTH.length > 0) {
        districtTH[0].setAttribute('style', `left:${bWidth}px`);
        schoolTH[0].setAttribute('style', `left:${dOffset}px`);
      }
    }
  });

  return <></>;
};

export default
@inject('dialogManager', 'reportsManager', 'playerManager')
@observer
class LPLClassPerformanceReport extends Component {
  constructor(props) {
    super(props);

    this.BreadCrumbs = SatCoreComponent('BreadCrumbs');

    this.state = {
      renderClassroomPopup: false,
      courseElementRow: null,
      courseName: null,
      courseId: null,
      reportEntityId: null,
      approvalDialog: null,
      reportId: 'EMPOWERED_COURSE'
    };
  }

  async componentDidMount() {
    const { reportsManager } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    console.log(urlParams.toString());
    navigationManager.setView(urlParams.get('view') || VIEW_SELECTION.CLASS_PERFORMANCE);

    const courseId = urlParams.get('courseId');
    const reportEntityId = urlParams.get('reportEntityId');
    const courseName = urlParams.get('courseName');
    this.setState({ courseName, courseId, reportEntityId });

    // Add the page to the breadcrumbs
    navigationManager.addPath({
      currentCourseId: courseId,
      currentClassroomId: null,
      currentElementId: null,
      name: courseName,
      type: PATH_TYPES.LINK,
      treeNavigationFunction: null // only valid if type
    });

    await reportsManager.fetchLPLClassPerformanceData(reportEntityId);
  }

  // extracts the data to render the course and course element detail data rows
  processClassPerformanceReportData = (lplClassPerformanceReportData) => { // eslint-disable-line no-unused-vars
    const { classrooms, courseElements, grades } = lplClassPerformanceReportData.data;
    merge(grades, this.loadStorageData().grades || {});
    const maxHeaderCols = Object.keys(courseElements).length + 3;

    // this will hold the row data
    const rowList = [];

    // define header columns
    const headerRow = {
      headerColumns: []
    };

    if (classrooms && classrooms.length > 0) {
      let createHeaderCols = true;
      Object.keys(classrooms).forEach(function (classroomIdx) {
        const classroom = classrooms[classroomIdx];
        const classroomRow = {
          classroomId: classroom.classroomId,
          elementColumns: []
        };

        // District Name Column
        const districtName = classroom.districtName.replace(/(<([^>]+)>)/gi, '');
        const districtNameColumn = {
          name: districtName
        };
        classroomRow.elementColumns.push(districtNameColumn);

        // School Name Column
        const schoolName = classroom.schoolName.replace(/(<([^>]+)>)/gi, '');
        const schoolNameColumn = {
          name: schoolName
        };
        classroomRow.elementColumns.push(schoolNameColumn);

        // Classroom Column
        const classroomName = classroom.classroomName.replace(/(<([^>]+)>)/gi, '');
        const classroomNameColumn = {
          name: classroomName,
          schoolName,
          teamLead: classroom.teamLead,
          email: this.validateEmail(classroom.email)
        };
        classroomRow.elementColumns.push(classroomNameColumn);

        // only create these once.
        if (createHeaderCols) {
          const districtNameHeaderCol = {
            title: 'District',
            subTitle: null
          };
          headerRow.headerColumns.push(districtNameHeaderCol);

          const schoolNameHeaderCol = {
            title: 'School',
            subTitle: null
          };
          headerRow.headerColumns.push(schoolNameHeaderCol);

          const classroomNameHeaderCol = {
            title: 'Classroom',
            subTitle: null
          };
          headerRow.headerColumns.push(classroomNameHeaderCol);

          createHeaderCols = false;
        }

        // Loop over courseElements to build Benchmark Columns
        Object.keys(courseElements).forEach(function (courseElementIdx) {
          const courseElement = courseElements[courseElementIdx];

          // find the grade for the row/column
          let classroomGrades = {};
          let classroomGrade = {};
          Object.keys(grades).forEach(function (gradeIdx) {
            if (gradeIdx === courseElement.elementId) {
              classroomGrades = grades[gradeIdx];
              Object.keys(classroomGrades).forEach((classroomGradeIdx) => {
                if (classroomGradeIdx === classroom.classroomId) {
                  classroomGrade = classroomGrades[classroomGradeIdx];
                }
              }, this);
            }
          }, this);

          const assessmentColumn = {
            classroomId: classroom.classroomId,
            elementId: courseElement.elementId,
            approvalStatus: classroomGrade.approvalStatus,
            grade: classroomGrade.grade,
            studentCount: classroomGrade.studentCount,
            viewedStatus: classroomGrade.viewedStatus,
            activityId: classroomGrade.activityId,
            title: courseElement.subtitle
          };
          classroomRow.elementColumns.push(assessmentColumn);

          // create assessment header columns, but only one set so skip after we've created them once.
          if (headerRow.headerColumns.length < maxHeaderCols) {
            const assessmentHeaderCol = {
              elementId: courseElement.elementId,
              title: courseElement.title,
              subTitle: (!classroomGrade.approvalStatus && classroomGrade.grade) ? 'Quiz (Taken/Avg)' : courseElement.subtitle
            };
            headerRow.headerColumns.push(assessmentHeaderCol);
          }
        }, this);
        rowList.push(classroomRow);
      }, this);
    }

    // stick the header at the top
    rowList.unshift(headerRow);
    // console.log(rowList);
    return rowList;
  }

  validateEmail = (email) => {
    if (email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
      if (re.test(String(email).toLowerCase())) {
        return String(email).toLowerCase();
      }
      return 'n/a';
    }
    return 'n/a';
  }

  // Render the headers after the first column
  renderHeaderColumns = (headerCols) => (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell className='header-column blankH' />
        {headerCols.map((headerCol, idx) => {
          const title = (headerCol.title) ? ReactHtmlParser(headerCol.title.replace(/(<([^>]+)>)/gi, '')) : 'No Title';
          const subTitle = (headerCol.subTitle) ? ReactHtmlParser(headerCol.subTitle.replace(/(<([^>]+)>)/gi, '')) : '';
          const isSchool = (title[0] === 'School');
          const isDistrict = (title[0] === 'District');

          return (
            <React.Fragment key={idx}>
              {subTitle && (
                <Popup
                  content={(
                    <>
                      <div>{title}</div>
                      <div>{subTitle}</div>
                    </>
                  )}
                  position='bottom right'
                  trigger={(
                    <Table.HeaderCell className={classNames({ district: (title === 'District'), school: (title === 'School') }, 'header-column')}>
                      <div className='header-column-text'>{title}</div>
                      <div className='header-column-text'>{subTitle}</div>
                    </Table.HeaderCell>
                      )} />
              )}
              {!subTitle && (
                <Table.HeaderCell className={classNames({ district: isDistrict, school: isSchool }, 'header-column')}>
                  <div className='header-column-text'>{title}</div>
                  <div className='header-column-text'>{subTitle}</div>
                </Table.HeaderCell>
              )}
            </React.Fragment>
          );
        })}
      </Table.Row>
    </Table.Header>
  )

  // Element rows have the element name and then detail cells
  renderContentDetailRows = (reportRows) => {
    const headerRow = reportRows[0];
    const elementRows = reportRows.slice(1);
    return (
      <>
        {this.renderHeaderColumns(headerRow.headerColumns)}
        <Table.Body>
          {elementRows.map((detailRow, idx) => (
            <React.Fragment key={idx}>
              <Table.Row key={`classroom-${detailRow.classroomId}_row_${idx}`} className={`classroom-${detailRow.classroomId}`} id={`classroom-${detailRow.classroomId}_row_${idx}`} name={`classroom-${detailRow.classroomId}_row_${idx}`}>
                <Table.Cell key={`classroom-${detailRow.classroomId}_index_${idx}`} className={`classroom-${detailRow.classroomId}-index freeze-me one`} scope='row'>
                  {idx + 1}
                </Table.Cell>
                {detailRow.elementColumns.map((detailCol, idx) => {
                  if (idx === 0) {
                    return (
                      <Table.Cell key={`classroom-${detailRow.classroomId}_row_${idx}`} className={`classroom-${detailRow.classroomId}-district freeze-me district two`} scope='row'>
                        {ReactHtmlParser(detailCol.name)}
                      </Table.Cell>
                    );
                  } else if (idx === 1) {
                    return (
                      <Table.Cell key={`classroom-${detailRow.classroomId}_row_${idx}`} className={`classroom-${detailRow.classroomId}-school school freeze-me three`} scope='row'>
                        {ReactHtmlParser(detailCol.name)}
                      </Table.Cell>
                    );
                  } else if (idx === 2) {
                    return (
                      <Table.Cell key={`classroom-${detailRow.classroomId}_row_${idx}`} className={`classroom-${detailRow.classroomId}-classroom  `}>
                        <Popup
                          className='class-performance-popup'
                          on='click'
                          pinned
                          trigger={<div className='classroom-clickable-title'>{ReactHtmlParser(detailCol.name)}</div>}
                          wide>
                          <Popup.Content>
                            <div className='popup-content-wrapper'>
                              <div className='school-title'>{ReactHtmlParser(detailCol.schoolName)}</div>
                              <div className='classroom-title'>
                                <span className='popup-label'>Class:</span>
                                <span>{ReactHtmlParser(detailCol.name)}</span>
                              </div>
                              <div className='team-lead-name'>
                                <span className='popup-label'>Team Lead:</span>
                                <span>{ReactHtmlParser(detailCol.teamLead)}</span>
                              </div>
                              <div className='email'>
                                <span className='popup-label'>Email:</span>
                                <span>{ReactHtmlParser(detailCol.email)}</span>
                              </div>
                            </div>
                          </Popup.Content>
                        </Popup>
                      </Table.Cell>
                    );
                  }
                  if (detailCol.approvalStatus) {
                    let statusStyle = '';
                    let statusIcon = '';
                    if (detailCol.approvalStatus === 'approved') {
                      statusStyle = 'positive';
                      statusIcon = iconComplete;
                    } else if (detailCol.approvalStatus === 'not-approved') {
                      statusStyle = 'negative';
                      statusIcon = iconNotComplete;
                    }
                    return (
                      <Table.Cell
                        key={`classroom-${detailRow.classroomId}_row_${idx}`}
                        className={`classroom-benchmark ${statusStyle}`}
                        onClick={() => this.openApprovalDialog(detailCol)}>
                        {statusStyle && statusIcon && <Image name={`icon-${statusStyle}`} src={statusIcon} />}
                        {detailCol.viewedStatus === 'viewed' && <span className='dot' />}
                      </Table.Cell>
                    );
                  } else if (detailCol.grade) {
                    return (
                      <Table.Cell key={`classroom-${detailRow.classroomId}_row_${idx}`} className='classroom-quiz'>
                        {detailCol.studentCount}
&nbsp;/&nbsp;
                        {detailCol.grade}
                        %
                      </Table.Cell>
                    );
                  }
                  return (
                    <Table.Cell key={`classroom-${detailRow.classroomId}_row_${idx}`} className='classroom-no-grade'>
                      <div>--</div>
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            </React.Fragment>
          ))}
        </Table.Body>
      </>
    );
  }

  // Create the main report table structure
  renderReportTable = () => {
    const { reportsManager } = this.props;
    const { lplClassPerformanceReportData, lplClassPerformanceReportDataLoaded } = reportsManager;
    // extract the data
    let contentDetailRowList = null;
    if (lplClassPerformanceReportData && lplClassPerformanceReportData.data && lplClassPerformanceReportData.data.classrooms) {
      contentDetailRowList = this.processClassPerformanceReportData(lplClassPerformanceReportData);
    }
    return (
      <>
        {lplClassPerformanceReportDataLoaded && contentDetailRowList && (
        <div className='reportTableContainer'>
          <Table celled className='content-header-table' singleLine>
            {this.renderContentDetailRows(contentDetailRowList)}
          </Table>
          <MagicResizeComponent />
        </div>
        )}
        {lplClassPerformanceReportDataLoaded && !contentDetailRowList && (
        <div className='null-state-panel'>
          <div className='lightbulb-icon-background'><Image className='lightbulb-icon' src={iconLightbulb} /></div>
          <div className='null-state-text'>No assignments have been submitted for approval yet</div>
        </div>
        )}
        {!lplClassPerformanceReportDataLoaded && (
          <div className='null-state-panel'>
            <Loader key={0} active inline />
          </div>
        )}
      </>
    );
  }

  openApprovalDialog = (detailCol) => {
    const defaultValue = detailCol.viewedStatus === 'viewed' && ((detailCol.approvalStatus === 'approved' && APPROVAL.YES) ||
      (detailCol.approvalStatus === 'not-approved' && APPROVAL.NO));
    if (!detailCol.activityId) return;
    this.setState({
      approvalDialog: {
        assignment: { id: detailCol.activityId, name: detailCol.title },
        onApprove: () => this.setStorageData(detailCol, 'approved'),
        onDisApprove: () => this.setStorageData(detailCol, 'not-approved'),
        defaultValue
      }
    });
  }

  handleDownload = () => {
    const { dialogManager } = this.props;
    const { reportId, reportEntityId: entityId } = this.state;

    dialogManager.setOpenDialog(DIALOG_NAMES.EXPORT_REPORT, {
      baseReportId: reportId,
      entityId,
      reportId: `${reportId}_X`
    });
  }

  renderReportHeader = () => {
    const { history, reportsManager } = this.props;
    const { courseName } = this.state;
    const { lastModified } = reportsManager;
    const { BreadCrumbs } = this;
    return (
      <>
        <div className='report-nav'>
          <Container className='bread-crumb-wrapper top' fluid>
            <BreadCrumbs history={history} />
          </Container>
        </div>
        <div className='headerContainer'>
          <div className='headerWrapper'>
            <div>
              <Header as='h1'>{courseName}</Header>
              {(lastModified !== null) ? (
                <div className='report-context-row'>
                  <span className='label'>Last Modified:</span>
                  {' '}
                  {lastModified}
                </div>
              ) : null}
            </div>
            <div className='header-right-block'>
              <Button className='export-button' onClick={this.handleDownload} primary>
                <Image alt='' src={iconDownload} />
                Export
              </Button>
              <div className='status-key-panel'>
                <div className='key-item'>
                  <Image src={iconComplete} />&nbsp;Approved
                </div>
                <div className='key-item'>
                  <Image src={iconNotComplete} />&nbsp;Not Approved
                </div>
                <div className='key-item'>
                  <span className='dot' />&nbsp;Viewed
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  loadStorageData() {
    const { reportsManager: { lastModified } } = this.props;
    try {
      const data = JSON.parse(sessionStorage.getItem('LPLClassPerformanceReport') || '{}');
      return data.lastModified === lastModified ? data : { lastModified };
    } catch {
      return { lastModified };
    }
  }

  setStorageData(detailCol, status) {
    const data = this.loadStorageData();
    set(data, ['grades', detailCol.elementId, detailCol.classroomId], {
      viewedStatus: 'viewed',
      approvalStatus: status
    });
    sessionStorage.setItem('LPLClassPerformanceReport', JSON.stringify(data));
  }

  render() {
    const { playerManager } = this.props;
    const { approvalDialog } = this.state;

    return (
      <>
        <div className={classNames('class-performance', { 'hide-me': playerManager.playerShowing })}>
          {this.renderReportHeader()}
          <div className='class-reports-view'>
            <div className='reportContainer'>
              {this.renderReportTable()}
            </div>
          </div>
          {approvalDialog && (
          <ApprovalDialog
            closeApproval={() => this.setState({ approvalDialog: null })}
            label={null}
            openApproval={!!approvalDialog}
            {...approvalDialog} />
          )}
        </div>
        {(playerManager.playerShowing) && <PlayerComponent />}
      </>
    );
  }
}

SatCoreRegister('LPLClassPerformanceReport', LPLClassPerformanceReport);
