import {
  CONTENT_ITEM_TYPES,
  ImageService
} from 'sat-core';

import backgroundManager from '../managers/BackgroundImageManager';

ImageService.getImageUrl = (element) => {
  if (element === null || element === undefined) {
    return null;
  }
  let imageUrl = null;
  if (element.imageUrl !== '' && element.imageUrl !== null && element.imageUrl !== undefined) {
    imageUrl = CONTENT_ITEM_TYPES.getContentImageUrl(element.imageUrl);
  } else {
    imageUrl = backgroundManager.getCurrentImageUrl();
  }
  return imageUrl;
};
