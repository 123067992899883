import { reaction } from 'mobx';
import { userManager } from 'sat-core';

export default class InstitutionLogoService {
  static disposer = null;

  static initInstitutionLogoService() {
    InstitutionLogoService.disposer = reaction(
      () => userManager.userId,
      (id) => InstitutionLogoService.updateLogo(id),
      { fireImmediately: true });
  }

  static async updateLogo(id) {
    if (id !== '' && id !== null && id !== undefined) {
      await userManager.fetchInstitutionImage();
    }
  }

  static cleanUp() {
    if (InstitutionLogoService.disposer !== null && InstitutionLogoService.disposer !== undefined) {
      InstitutionLogoService.disposer();
    }
  }
}
