import { Button, Icon, Popup, Segment } from 'semantic-ui-react';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { CONTENT_ITEM_TYPES, NavigationService } from 'sat-core';
import '../css/RootLayout.less';
import ReactHtmlParser from 'react-html-parser';

export default
@inject('progressManager')
@observer
class CourseSegmentCard extends Component {
  handleClick = (courseElement) => {
    const { history } = this.props;
    NavigationService.handleCourseElementClick(courseElement, history);
  }

  render() {
    const { courseElement, progressManager } = this.props;
    const elementName = ReactHtmlParser(courseElement.name);
    const progress = progressManager.benchmarkProgress.get(courseElement.elementId);
    const backgroundImage = courseElement.attachmentContentItemId && `url(${CONTENT_ITEM_TYPES.getContentIdImageUrl(courseElement.attachmentContentItemId)})`;
    return (
      <Segment className='branch-card-satcore list-card' style={{ backgroundImage, backgroundPosition: backgroundImage && 'center' }}>
        <div className='card-body'>
          <Popup
            content={elementName}
            trigger={(
              <div
                className='branch-name clickable'
                onClick={() => { this.handleClick(courseElement); }}>
                <span>{elementName}</span>
              </div>
            )} />
          <div className='middle-card'>
            <div className='benchMark-progress'>{progress ? progress.progressFraction : ''}</div>
            <div className='benchmark-status'>{progress ? progress.progressMessage : ''}</div>
          </div>
        </div>
        <div className='card-nav-buttons'>
          <Button onClick={() => { this.handleClick(courseElement); }}>
            <span className='continue-label'>Continue</span>
            <Icon className='arrow-right' />
          </Button>
        </div>
      </Segment>
    );
  }
}
