import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { ImageService } from 'sat-core';
import { Image, Segment } from 'semantic-ui-react';
import ReactHtmlParser from 'react-html-parser';
import '../css/GradebookActivity.less';
import contentImageUrl from '../img/backgrounds/energy_audit-october-20.jpg';

export default @inject('gradebookManager')
@observer
class GradebookCard extends Component {
  render() {
    const { gradebookManager } = this.props;
    const { currentAssignment } = gradebookManager;

    ImageService.getImageUrl(currentAssignment);
    return (
      <Segment className='gradebook-card' vertical>
        {(contentImageUrl !== null && contentImageUrl !== undefined) ? <Image src={contentImageUrl} /> :
        <div className='image-placeholder' />}
        <div className='fg-col instruct'>
          <div className='fg-row'>
            <div className='card-label'>Notes:</div>
          </div>
          <div className='fg-row'>
            <div className='card-value-multi'>{ReactHtmlParser(currentAssignment.note)}</div>
          </div>
        </div>
      </Segment>
    );
  }
}
