import React, { Component } from 'react';
import { SatCoreRegister, VIEW_SELECTION } from 'sat-core';
import { inject, observer } from 'mobx-react';
import { Button, Image, Segment } from 'semantic-ui-react';
import ReactHtmlParser from 'react-html-parser';
import Speedometer from '../components/Speedometer';
import Trophy from '../components/Trophy';
import { TROPHY } from '../managers/ProgressManager';
import LeaderBoardModal from '../components/LeaderBoardModal';
import ProgressList from '../components/ProgressList';
import '../css/ProgressView.less';
import goldMedal from '../img/gold.svg';
import silverMedal from '../img/silver.svg';
import bronzeMedal from '../img/bronze.svg';

export default
@inject('progressManager', 'userManager', 'classroomManager', 'navigationManager', 'courseManager')
@observer
class ProgressViewOverride extends Component {
  constructor(props) {
    super(props);
    this.state = { openModal: false };
  }

  clickView = () => {
    this.setState({ openModal: true });
  }

  closeModal = () => {
    this.setState({ openModal: false });
  }

  async componentDidMount() {
    const { progressManager, classroomManager, navigationManager, courseManager } = this.props;
    const urlParams = new URLSearchParams(window.location.search);

    const classroomId = urlParams.get('classroomId');

    if (!!classroomId && classroomManager.currentClassroomId === null) {
      classroomManager.setCurrentClassroomId(classroomId);
    }
    navigationManager.setView(VIEW_SELECTION.PROGRESS);
    await courseManager.fetchClassroomCourses(classroomManager.currentClassroomId);

    const classroom = classroomManager.getClassroom(classroomId);

    await progressManager.fetchLeaderboard(classroom.institutionId, courseManager.currentCourseId);
    await progressManager.fetchClassroomProgress(classroomManager.currentClassroomId);
  }

  trophy = (place, label, key) => {
    switch (place) {
      case TROPHY.GOLD: {
        return (
          <div key={key} className='trophy-icon'>
            <Trophy color={TROPHY.GOLD} />
            <div className='trophy-text'>{ReactHtmlParser(label)}</div>
          </div>
        );
      }
      case TROPHY.SILVER: {
        return (
          <div key={key} className='trophy-icon'>
            <Trophy color={TROPHY.SILVER} />
            <div className='trophy-text'>{ReactHtmlParser(label)}</div>
          </div>
        );
      }
      case TROPHY.BRONZE: {
        return (
          <div key={key} className='trophy-icon'>
            <Trophy color={TROPHY.BRONZE} />
            <div className='trophy-text'>{ReactHtmlParser(label)}</div>
          </div>
        );
      }
      default: {
        return (
          <div key={key} className='trophy-icon'>
            <Trophy color={TROPHY.NONE} />
            <div className='trophy-text'>{ReactHtmlParser(label)}</div>
          </div>
        );
      }
    }
  }

  tableHeader = (t1, t2) => (
    <div className='table-header'>
      <div className='table-title'>{t1}</div>
      <div className='table-title right'>{t2}</div>
    </div>
  )

  topThreeSchools = () => {
    const { progressManager } = this.props;

    const { topThreeSchools } = progressManager;

    return (
      <table className='table'>
        <colgroup>
          <col width='5%' />
          <col width='80%' />
          <col width='10%' />
        </colgroup>
        <tbody className='table'>
          {(topThreeSchools[0] !== undefined) ? (
            <tr className='table-row'>
              <td className='badge'><Image src={goldMedal} /></td>
              <td className='inst-name'>{topThreeSchools[0].name}</td>
              <td className='score'>{(topThreeSchools[0].points !== undefined) ? topThreeSchools[0].points : 0}</td>
            </tr>
          ) : null}
          {(topThreeSchools[1] !== undefined) ? (
            <tr className='table-row'>
              <td className='badge'><Image src={silverMedal} /></td>
              <td className='inst-name'>{topThreeSchools[1].name}</td>
              <td className='score'>{(topThreeSchools[1].points !== undefined) ? topThreeSchools[1].points : 0}</td>
            </tr>
          ) : null}
          {(topThreeSchools[2] !== undefined) ? (
            <tr className='table-row'>
              <td className='badge'><Image src={bronzeMedal} /></td>
              <td className='inst-name'>{topThreeSchools[2].name}</td>
              <td className='score'>{(topThreeSchools[2].points !== undefined) ? topThreeSchools[2].points : 0}</td>
            </tr>
          ) : null}
        </tbody>
      </table>

    );
  }

  focusedList = () => {
    const { progressManager } = this.props;

    const { focusedList } = progressManager;

    return (
      <table className='table'>
        <colgroup>
          <col width='7%' />
          <col width='75%' />
          <col width='10%' />
        </colgroup>
        <tbody>
          {(focusedList[0] !== undefined) ? (
            <tr className='table-row'>
              <td className='inst-rank'>{focusedList[0].rank}</td>
              <td className='inst-name bottom'>{focusedList[0].name}</td>
              <td className='score'>{(focusedList[0].points !== undefined) ? focusedList[0].points : 0}</td>
            </tr>
          ) : null}
          {(focusedList[1] !== undefined) ? (
            <tr className='table-row'>
              <td className='inst-rank'>{focusedList[1].rank}</td>
              <td className='inst-name bottom'>{focusedList[1].name}</td>
              <td className='score'>{(focusedList[1].points !== undefined) ? focusedList[1].points : 0}</td>
            </tr>
          ) : null}
          {(focusedList[2] !== undefined) ? (
            <tr className='table-row'>
              <td className='inst-rank'>{focusedList[2].rank}</td>
              <td className='inst-name bottom'>{focusedList[2].name}</td>
              <td className='score'>{(focusedList[2].points !== undefined) ? focusedList[2].points : 0}</td>
            </tr>
          ) : null}
        </tbody>
      </table>
    );
  }

  render() {
    const { progressManager, classroomManager, userManager } = this.props;
    const { speedometerObject } = progressManager;
    const { completed } = speedometerObject;
    const { total } = speedometerObject;
    const rank = (progressManager.leaderboard.institutionRank === undefined) ? 0 : progressManager.leaderboard.institutionRank;
    const rankTotal = (progressManager.leaderboard.leaderboardTotal === undefined) ? 0 : progressManager.leaderboard.leaderboardTotal;
    const institutionName = (progressManager.leaderboard.institutionName === undefined) ? userManager.institutionName : progressManager.leaderboard.institutionName;

    return (
      <>
        {' '}
        {
        (classroomManager.currentClassroomId !== null
          && classroomManager.currentClassroomId !== undefined
          && progressManager.classroomProgress.length !== 0) ? (
            <div className='progress-view'>
              <div className='top-row'>
                {(rank !== undefined && rank !== null && rank > 0) ? null : (
                  <Segment className='title-message'>
                    <div className='title'>Your position on the Leaderboard will appear when the LPL Admin approves your sent activities.</div>
                  </Segment>
                )}
                <Segment className='widget widget-one'>
                  <div className='header-bar'>Classroom Progress</div>
                  <div className='title'>Lesson Progress</div>
                  <div className='speed-wrapper'>
                    <Speedometer complete={completed} total={total} />
                    <div className='speed-label'>
                      <div className='speed-text bold'>{`${completed} out of ${total}`}</div>
                      <div className='speed-text'>Lessons Complete</div>
                    </div>
                  </div>
                  <div className='trophy-list'>
                    {progressManager.classroomProgress.map((item) => this.trophy(item.trophy, item.courseResourceElementName, item.courseResourceElementId))}
                  </div>
                </Segment>
                <Segment className='widget widget-two'>
                  <div className='header-bar'>Leaderboard</div>
                  <div className='title'>{`${institutionName} is ${rank} out of ${rankTotal}`}</div>
                  <div className='table-wrapper'>
                    {this.tableHeader('Top 3 Schools', 'Points')}
                    {this.topThreeSchools()}
                  </div>
                  <div className='table-wrapper'>
                    {this.tableHeader('All Schools', 'Points')}
                    {this.focusedList()}
                  </div>
                  <Button className='view-button' onClick={this.clickView}>VIEW ALL</Button>
                </Segment>
              </div>
              <ProgressList history={this.props.history} />
              <LeaderBoardModal closeModal={this.closeModal} openModal={this.state.openModal} />
            </div>
          ) :
          null
      }
      </>
    );
  }
}

SatCoreRegister('ProgressView', ProgressViewOverride);
