import React, { Component } from 'react';
import { SatCoreComponent, SatCoreRegister } from 'sat-core';
import { inject, observer } from 'mobx-react';
import ReactHtmlParser from 'react-html-parser';
import {
  Icon,
  Item,
  Segment
} from 'semantic-ui-react';
// import globe from '../img/globe.png';

import '../css/AssignmentCard.less';

export default
@inject('assignmentManager', 'courseManager')
@observer
class AssignmentCard extends Component {
  constructor(props) {
    super(props);
    this.StandardsList = SatCoreComponent('StandardsList');
  }

  openApproval = () => {
    this.props.openApproval(this.props.assignment);
  }

  getStatus = () => {
    const { assignment } = this.props;

    const now = new Date();
    const nowTime = now.getTime();
    const seventyTwoHours = 72 * 60 * 60 * 1000; // in milliseconds
    const submittedDate = new Date(assignment.timezoneEndDate); // for lpl the enddate = submitted date
    const difference = nowTime - submittedDate.getTime();

    if (difference > seventyTwoHours) {
      return (
        <>
          <Icon className='status-icon' name='warning circle' />
          <div className='status-name card-value'>Overdue</div>
        </>
      );
    }

    return (
      <>
        <Icon className='status-icon' name='clock' />
        <div className='status-name card-value'>Awaiting</div>
      </>
    );
  }

  render() {
    const { assignment } = this.props;

    return (

      <Item key={assignment.id} as={Segment} className='assignment-card'>
        <Item.Content>
          <div className='flex-grid main'>
            <div className='card-grid-container'>
              <div className='flex-grid header'>
                <div className='flex-grid row header'>
                  <div className='flex-header'>
                    {(assignment.resourceWebTitle !== '' && assignment.resourceWebTitle != null) ?
                      ReactHtmlParser(assignment.resourceWebTitle) : assignment.name}
                  </div>
                </div>
              </div>
              <div className='flex-grid'>
                <div className='flex-grid row'>
                  <div className='subtitle'>
                    <div className='expander'>
                      {
                        (assignment.description !== '' && assignment.description !== null && assignment.description !== undefined) ?
                          ReactHtmlParser(assignment.description)
                          :
                          null
                      }
                    </div>
                    {
                      (assignment.description !== '' && assignment.description !== null && assignment.description !== undefined) ?
                        <span className='spacer'>|</span> : <></>
                    }
                    <div className='expander'>
                      Course:
                      {assignment.courseContentItemName}
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex-grid bottom'>
                <div className='flex-grid column'>
                  <div className='flex-grid cell status'>
                    <div className='card-label'>Status:</div>
                    {this.getStatus()}
                  </div>
                  <div className='flex-grid  cell  submitted'>
                    <div className='card-label'>Submitted Date:</div>
                    <div className='card-value'>{assignment.timezoneEndDate}</div>
                  </div>
                </div>
                <div className='flex-grid column'>
                  <div className='flex-grid cell status'>
                    <div className='card-label'>District:</div>
                    <div className='card-value'>{assignment.parentInstitutionName}</div>
                  </div>
                  <div className='flex-grid  cell  submitted'>
                    <div className='card-label'>School:</div>
                    <div className='card-value'>{assignment.institutionName}</div>
                  </div>
                </div>
                <div className='flex-grid column'>
                  <div className='flex-grid cell status'>
                    <div className='card-label'>Classroom:</div>
                    <div className='card-value'>
                      {(assignment.classroomNickname !== null && assignment.classroomNickname !== '' && assignment.classroomNickname !== undefined)
                        ? assignment.classroomNickname : assignment.classroomName}
                    </div>
                  </div>
                  <div className='flex-grid  cell  submitted'>
                    <div className='card-label'>Teacher:</div>
                    <div className='card-value'>{`${assignment.assignerFirstName} ${assignment.assignerLastName}`}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card-button-container card-nav-buttons'>
              <div className='button-label' onClick={this.openApproval}>OPEN</div>
              <Icon className='button-arrow' name='right arrow' onClick={this.openApproval} />
            </div>
          </div>
        </Item.Content>
      </Item>
    );
  }
}

SatCoreRegister('AssignmentCard', AssignmentCard);
