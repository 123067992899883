import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  CONTENT_ITEM_TYPES,
  CourseService,
  MainView,
  SatCoreComponent,
  SatCoreRegister,
  VIEW_SELECTION
} from 'sat-core';
import { Dropdown, Image, Menu } from 'semantic-ui-react';
import { Route } from 'react-router-dom';
import Footer from './Footer';
import GradebookActivity from './GradebookOverride';
import InstitutionLogoService from '../services/InstitutionLogoService';
import LPLReportsView from './reports/LPLReportsView';
import LPLClassPerformanceReport from './reports/LPLClassPerformanceReport';
import BackgroundService from '../services/BackgroundService';
import hamburgClose from '../img/hamburg-close.svg';
import homeIcon from '../img/icon-classes.svg';
import dashIcon from '../img/icon-progress.svg';
import workspaceIcon from '../img/icon-workspace.svg';

@inject('appManager', 'classroomManager', 'courseManager', 'navigationManager', 'userManager')
@observer
class MainViewOverride extends Component {
  constructor(props) {
    super(props);
    this.ChangePassword = SatCoreComponent('ChangePassword');
  }

  async componentDidMount() {
    InstitutionLogoService.initInstitutionLogoService();
    BackgroundService.initBackgroundImageService();
  }

  showClasses = async (props) => {
    props.navigationManager.setView(VIEW_SELECTION.DASHBOARD);
    if (this.props.userManager.isStudent) {
      this.props.courseManager.setCurrentElementId('');// go back to root
      await CourseService.initCourseData(true, true, true);
    }
    props.history.push(`/?view=${VIEW_SELECTION.DASHBOARD}`);
  };

  showReports = async (props) => {
    props.navigationManager.setView(VIEW_SELECTION.REPORTS);
    await props.history.push(`/reports?view=${VIEW_SELECTION.REPORTS}`);
  }

  showProgress = (props) => {
    const { classroomManager } = this.props;
    props.navigationManager.setView(VIEW_SELECTION.PROGRESS);
    props.history.push(`/progress?classroomId=${classroomManager.currentClassroomId}&view=${VIEW_SELECTION.PROGRESS}`);
  };

  showWorkspace = (props) => {
    props.navigationManager.setView(VIEW_SELECTION.WORKSPACE);
    props.history.push(`/workspace?view=${VIEW_SELECTION.WORKSPACE}`);
  }

  getRoutes = (props, parent) => {
    const {
      appManager,
      userManager
    } = props;

    const {
      isSatAdmin, isSsoUser, isStudent, isTeacher
    } = userManager;

    // eslint-disable-next-line no-nested-ternary
    const Dashboard = isSatAdmin ? LPLReportsView : (isTeacher ? parent.Dashboard : parent.ClassDetails);

    const {
      ArchivedClasses,
      ClassCourses,
      ClassDetails,
      ClassRoster,
      ClassSettingsView,
      ClassWorkspace,
      Gradebook,
      NotificationsView,
      Profile,
      ProgressView,
      Verification
    } = parent;

    const { ChangePassword } = this;

    return (
      <>
        <Route
          exact
          path='/'
          render={(props) => (
            <Dashboard {...props} addClassOnly={true} />
          )} />
        <Route component={ClassCourses} path='/courses' />
        <Route component={ClassDetails} path='/class' />
        {isTeacher && !isSsoUser && <Route component={ArchivedClasses} exact path='/archived-classes' />}
        {(isTeacher) ? <Route component={Gradebook} exact path='/gradebook' /> : null}
        {(isTeacher) ? <Route component={GradebookActivity} exact path='/gradebook_activity' /> : null}
        <Route component={Profile} exact path='/profile' />
        <Route component={ChangePassword} exact path='/change-password' />
        <Route component={Verification} exact path='/verification' />
        <Route component={ProgressView} path='/progress' />
        {(isTeacher) ? <Route component={ClassRoster} path='/roster' /> : null}
        {(isTeacher) ? <Route component={ClassSettingsView} path='/settings' /> : null}
        {appManager.classWorkspaceFeature && <Route component={ClassWorkspace} path='/workspace' />}
        <Route component={LPLReportsView} exact path='/reports' />
        <Route component={LPLClassPerformanceReport} exact path='/reports/class-performance-report' />

        {(!isStudent) && (<Route component={NotificationsView} path='/notifications' />)}
      </>
    );
  }

  getHamburger = () => (
    <div className='hamburger-button'>
      <div className='hamburger'>
        <div className='hamburger-layer top-bun' />
        <div className='hamburger-layer savory-patty' />
        <div className='hamburger-layer bottom-bun' />
      </div>
      <div className='x-box'>
        <Image className='x-close' src={hamburgClose} />
      </div>
    </div>
  )

  setCurrentClassroomId = async (id) => {
    const { courseManager, classroomManager } = this.props;
    classroomManager.setCurrentClassroomId(id);
    courseManager.setCurrentElementId('ROOT');
    await CourseService.loadStudentCourses();
    await CourseService.initCourseData(true, true, true);
    this.props.history.replace(`/class?classroomId=${classroomManager.currentClassroomId}&courseId=${courseManager.currentCourseId}&elementId=ROOT&view=${VIEW_SELECTION.BOOK}`);
  }

  getNavTabs = (props, parent) => {
    const { classroomManager } = this.props;
    const { appManager, navigationManager, userManager } = props;
    const { viewSelection } = navigationManager;

    if (userManager.isSatAdmin) {
      return (
        <>
          <Menu.Item
            className={(viewSelection === VIEW_SELECTION.PROGRESS) ? 'tnav-tab selected' : 'tnav-tab'}
            onClick={() => this.showReports(props)}>
            Reports
          </Menu.Item>
        </>
      );
    }
    return (
      <>
        <div className='tab-menu'>
          <Menu.Item
            className={(viewSelection === VIEW_SELECTION.DASHBOARD) ? 'tnav-tab selected' : 'tnav-tab'}
            onClick={() => {
              this.showClasses(props);
            }}>
            <Image src={homeIcon} />
            Home
          </Menu.Item>
          {(classroomManager.currentClassroomId !== null && classroomManager.currentClassroomId !== undefined) ? (
            <Menu.Item
              className={(viewSelection === VIEW_SELECTION.PROGRESS) ? 'tnav-tab selected' : 'tnav-tab'}
              onClick={() => {
                this.showProgress(props);
              }}>
              <Image src={dashIcon} />
              Dashboard
            </Menu.Item>
          ) : null}
          {userManager.isStudent && appManager.classWorkspaceFeature && (
          <Menu.Item
            className={(viewSelection === VIEW_SELECTION.WORKSPACE) ? 'tnav-tab selected' : 'tnav-tab'}
            onClick={() => {
              this.showWorkspace(props);
            }}>
            <Image src={workspaceIcon} />
            Workspace
          </Menu.Item>
          )}
        </div>
        <div className='hamburger-menu'>
          <Menu.Item>
            <Dropdown icon={null} trigger={this.getHamburger()}>
              <Dropdown.Menu>
                <Dropdown.Item image={homeIcon} onClick={() => this.showClasses(props)} text='Home' />
                {(classroomManager.currentClassroomId !== null && classroomManager.currentClassroomId !== undefined) ? <Dropdown.Item image={dashIcon} onClick={() => this.showProgress(props)} text='Dashboard' /> : null}
                {userManager.isStudent && appManager.classWorkspaceFeature && <Dropdown.Item image={workspaceIcon} onClick={() => this.showWorkspace(props)} text='Workspace' />}
                <Dropdown.Item image={{ avatar: true, src: this.props.userManager.fullProfileImageUrl }} onClick={parent.showProfile} text='Profile' />
                <Dropdown.Item onClick={parent.handleLogout} text='Logout' />
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
        </div>
      </>
    );
  }

  render() {
    const { userManager } = this.props;
    let image = null;
    if (userManager.institutionImage !== null) {
      image = CONTENT_ITEM_TYPES.getContentIdImageUrl(userManager.institutionImage);
    }
    return (
      <>
        <MainView
          getNavTabs={this.getNavTabs}
          getRoutes={this.getRoutes}
          topNav={{ profileMenu: { archivedClasses: false } }}
          setCurrentClassroomId={this.setCurrentClassroomId} />
        {(userManager.isSatAdmin) ? <></> : <Footer image={image} />}
      </>
    );
  }
}

SatCoreRegister('MainView', MainViewOverride);
