import { Auth, contentManager } from 'sat-core';

export default class ContentItemService {
  static async getContentItem(contentItemId) {
    await contentManager.fetchResourceItem(contentItemId);
  }

  static generateStreamUrl = (entityId, entityTypeId) => {
    let streamUrl = `${Auth.ecms}/api/redirectToStreamUrl`;
    streamUrl += `?authKey=${Auth.authKey}`;
    streamUrl += `&entityId=${entityId}`;
    streamUrl += `&entityTypeId=${entityTypeId}`;
    streamUrl += `&cacheBuster=${(new Date().getTime())}`;
    return streamUrl;
  }
}
