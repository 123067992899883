import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import ReactHtmlParser from 'react-html-parser';
import { Header } from 'semantic-ui-react';
import { CarouselContainer, VIEW_SELECTION } from 'sat-core';
import useMediaQuery from 'react-use-media-query-hook';
import CourseSegmentCard from '../components/CourseSegmentCard';
import TeacherTrainingCard from '../components/TeacherTrainingCard';

import '../css/RootLayout.less';

const TeacherActivities = (props) => {
  const isMobile = useMediaQuery('(max-width: 465px)');
  const isMedium = useMediaQuery('(max-width: 1233px)');
  const isSmall = useMediaQuery('(max-width: 1060px)');
  const isTablet = useMediaQuery('(max-width: 952px)');
  const isSmallTablet = useMediaQuery('(max-width: 740px)');

  const { teacherElements, history } = props;

  let rootSlides = (isSmallTablet) ? 1 : (isTablet) ? 2 : (isSmall) ? 2 : (isMedium) ? 3 : 3;

  if (teacherElements.length < rootSlides) {
    rootSlides = teacherElements.length;
  }
  const rootSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: rootSlides,
    slidesToScroll: 1
  };

  return (
    <>
      {isMobile ? (
        <div>
          {teacherElements.map((courseElement) => (
            <TeacherTrainingCard
              key={`ttc_${courseElement.id}`}
              courseElement={courseElement}
              history={history} />
          )
          )}
        </div>
      )
        : (
          <CarouselContainer {...rootSettings}>
            {teacherElements.map((courseElement) => (
              <TeacherTrainingCard
                key={`ttc_${courseElement.id}`}
                courseElement={courseElement}
                history={history} />
            )
            )}
          </CarouselContainer>
        )}
    </>
  );
};

const BenchMarks = (props) => {
  const isMobile = useMediaQuery('(max-width: 465px)');
  const isMedium = useMediaQuery('(max-width: 1233px)');
  const isSmall = useMediaQuery('(max-width: 1060px)');
  const isTablet = useMediaQuery('(max-width: 952px)');
  const isSmallTablet = useMediaQuery('(max-width: 740px)');

  const { benchMarkElements, history } = props;

  let benchSlides = (isSmallTablet) ? 1 : (isTablet) ? 2 : (isSmall) ? 3 : (isMedium) ? 3 : 4;

  if (benchMarkElements.length < benchSlides) {
    benchSlides = benchMarkElements.length;
  }

  const benchMarkSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: benchSlides,
    slidesToScroll: 1
  };

  return (
    <>
      {
    (isMobile) ? (
      <>
        {benchMarkElements.map((courseElement) => (
          <CourseSegmentCard
            key={`csc_${courseElement.id}`}
            courseElement={courseElement}
            history={history} />
        )
        )}
      </>
    ) : (
      <CarouselContainer {...benchMarkSettings}>
        {benchMarkElements.map((courseElement) => (
          <CourseSegmentCard
            key={`csc_${courseElement.id}`}
            courseElement={courseElement}
            history={history} />
        )
        )}
      </CarouselContainer>
    )
}
    </>
  );
};

export default
@inject('userManager', 'classroomManager', 'courseManager', 'navigationManager')
@observer
class RootLayout extends Component {
  componentDidMount() {
    const { userManager, navigationManager } = this.props;
    navigationManager.setView(userManager.isStudent ? VIEW_SELECTION.DASHBOARD : VIEW_SELECTION.BOOK);
  }

  render() {
    const {
      teacherTrainingElement,
      benchMarkElement,
      teacherElements,
      benchMarkElements,
      userManager,
      history
    } = this.props;
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const date = new Date();

    if (teacherElements !== undefined && benchMarkElements !== undefined) {
      return (
        <div className='empower-course-root'>
          {userManager.isTeacher ? (
            <div className='unit-section teacher-training'>
              <div className='teacher-training-title'>
                <h1>{ReactHtmlParser(teacherTrainingElement.name)}</h1>
                {teacherTrainingElement.description.length > 0 ?
                  <h3 className='teacher-training-subtitle'>{ReactHtmlParser(teacherTrainingElement.description)}</h3> :
                  <></>}
              </div>
              <TeacherActivities history={history} teacherElements={teacherElements} />
            </div>
          ) : null}
          {userManager.isStudent ? (
            <div className='headerContainer'>
              <div className='headerWrapper'>
                <Header as='h1' className='welcome-header'>
                  Welcome&nbsp;
                  <br className='mobile-only' />
                  {userManager.firstName}
                  !
                </Header>
                <div
                  className='welcome-date'>
                  {`${days[date.getDay()]}, ${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`}
                </div>
              </div>
            </div>
          ) : null}
          <div className='unit-section program-benchmarks'>
            <div className='program-benchmarks-title'>
              <h1>{ReactHtmlParser(benchMarkElement.name)}</h1>
              {benchMarkElement.description.length > 0 ?
                <h3 className='teacher-training-subtitle'>{ReactHtmlParser(benchMarkElement.description)}</h3> :
                <></>}
            </div>
            <BenchMarks benchMarkElements={benchMarkElements} history={history} />
          </div>
        </div>
      );
    }
    return (<></>);
  }
}
