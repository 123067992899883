import { action, makeObservable, observable } from 'mobx';
import { Auth } from 'sat-core';

const REPORTS_ENDPOINTS = {
  LPL_CLASS_PERF: '/api/approveActivity',
  LPL_REPORT_VIEW: '/api/getPublisherCourses'
};

export class ReportsManager {
  taggedCourses = {};

  @observable lplClassReportViewData = [];

  @observable lplClassReportViewDataLoaded = false;

  @observable lplClassPerformanceReportData = {};

  @observable lplClassPerformanceReportDataLoaded = false;

  @observable lastModified = null;

  entityId = '';

  constructor() {
    makeObservable(this);
  }

  @action
  clearAll = () => {
    this.lplClassPerformanceReportData = {};
    this.lplClassPerformanceReportDataLoaded = false;
    this.lplClassReportViewData = [];
    this.entityId = '';
    this.lastModified = null;
  }

  @action setLoadedClassReportViewDataLoaded(toggle) {
    this.lplClassReportViewDataLoaded = toggle;
  }

  @action setLoadedLPLClassPerformanceReportData(toggle) {
    this.lplClassPerformanceReportDataLoaded = toggle;
  }

  @action setLPLClassPerformanceData = (lplClassPerformanceData) => {
    this.lplClassPerformanceReportData = lplClassPerformanceData;
  }

  @action setLPLClassReportViewData = (data = null) => {
    this.lplClassReportViewData = data || this.taggedCourses;
  }

  // Data needed to render the class performance report grid
  @action fetchLPLClassPerformanceData = async (entityId) => {
    const reportId = 'EMPOWERED_COURSE';
    if (entityId === this.entityId && this.lplClassPerformanceReportData) {
      // already have report data for this entity and report
      return true;
    }
    this.clearAll();
    this.entityId = entityId;

    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/viewReportOutput?reportId=${reportId}&entityId=${entityId}`, {
        method: 'GET'
      });

      if (response !== null && response.status === 'SUCCESS') {
        this.lplClassPerformanceReportData = response.data[0].contentJson;
        this.lastModified = response.timezoneReportDate;
        return true;
      }
    } catch (e) {
      console.log(e);
      // Setting the token in Cookies
      return false;
    } finally {
      this.setLoadedLPLClassPerformanceReportData(true);
    }
  }

  @action fetchLPLReportsViewData = async (categoryName = 'SATELLITE', tagName = 'EMP', includeCourseIfNoSelectedTags = true) => {
    try {
      this.setLoadedClassReportViewDataLoaded(false);
      const response = await Auth.fetch(`${Auth.ecms}${REPORTS_ENDPOINTS.LPL_REPORT_VIEW}`, {
        method: 'GET'
      });
      const reportData = { ...response };
      if (reportData) {
        for (const stateCode in reportData) {
          this.taggedCourses[stateCode] = [];
          this.setLPLClassReportViewData(this.taggedCourses);
          reportData[stateCode].forEach((course) => {
            const apiUrl_tagCategories = `${Auth.ecms}/api/getContentItemCategoriesAndTags?contentItemId=${course.contentItemId}`;
            Auth.fetch(apiUrl_tagCategories, { method: 'GET' }).then((response) => {
              if (response && response.categories && Array.isArray(response.categories)) {
                const categories = [...response.categories];
                const categoryArr = categories.filter((category) => category.name && category.name.toUpperCase() === categoryName.toUpperCase());
                const hasNoTags = !categoryArr[0] || (categoryArr[0].tags && !categoryArr[0].tags.length);
                if (hasNoTags && includeCourseIfNoSelectedTags) {
                  this.taggedCourses[stateCode].push(course);
                  this.setLPLClassReportViewData(this.taggedCourses);
                } else {
                  let hasNoSelectedTags = true;
                  let hasSpecifiedTag = false;
                  const { tags } = categoryArr[0];
                  for (let i = 0; i < tags.length; i++) {
                    const tag = tags[i];
                    if (typeof tag.name === 'string' && tag.selected) {
                      hasSpecifiedTag = hasSpecifiedTag || tag.name.toUpperCase().includes(tagName.toUpperCase());
                      hasNoSelectedTags = false;
                    }
                    const lastIndex = i === tags.length - 1;
                    if (lastIndex && (hasSpecifiedTag || (hasNoSelectedTags && includeCourseIfNoSelectedTags))) {
                      this.taggedCourses[stateCode].push(course);
                      this.setLPLClassReportViewData(this.taggedCourses);
                    }
                  }
                }
              }
            });
          });
        }
        setTimeout(() => {
          this.setLoadedClassReportViewDataLoaded(true);
        }, 700);
      }
    } catch (error) {
      console.error(error);
      this.setLoadedClassReportViewDataLoaded(true);
      return null;
    }
  }
}

export default new ReportsManager();
