import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { CONTENT_ITEM_TYPES, VIEW_SELECTION } from 'sat-core';
import { Card, Loader } from 'semantic-ui-react';
import FirstContentCard from '../components/FirstContentCard';
import ActionCard from '../components/ActionCard.js';

import '../css/CourseContent.less';
import BackgroundService from '../services/BackgroundService';

@inject('courseManager', 'navigationManager', 'classroomManager', 'userManager')
@observer
class CourseContent extends Component {
  componentDidMount() {
    this.props.navigationManager.setView(VIEW_SELECTION.BOOK);
    if (BackgroundService.sectionImageIsNull()) {
      BackgroundService.getBackgroundImage();
    }
  }

  renderTree = () => {
    const { courseManager, userManager, history } = this.props;

    const { currentCourseElementList } = courseManager;
    const { isTeacher } = userManager;

    return (
      <>
        {currentCourseElementList.map((courseElement, currentIndex) => (
          <Fragment key={`${courseElement.id}-B`}>
            {(currentIndex === 0 && currentCourseElementList.length > 1 &&
                courseElement.type !== CONTENT_ITEM_TYPES.ASSESSMENT
                && courseElement.type !== CONTENT_ITEM_TYPES.LESSON) ? (
                  <FirstContentCard
                    courseElement={courseElement}
                    handleClick={this.handleClick}
                    history={history}
                    isTeacher={isTeacher} />
              ) : (
                <ActionCard
                  courseElement={courseElement}
                  handleClick={this.handleClick}
                  history={history}
                  isTeacher={isTeacher} />
              )}
          </Fragment>
        )
        )}
      </>
    );
  }

  renderPlaceHolder = () => (
    <>
      <Loader active />
    </>
  )

  render() {
    const { courseManager } = this.props;
    const { currentCourseElementList } = courseManager;
    return (
      <Card.Group>
        {
          (!currentCourseElementList || currentCourseElementList.length <= 0)
            ? this.renderPlaceHolder()
            : this.renderTree()
        }
      </Card.Group>
    );
  }
}

export default CourseContent;
