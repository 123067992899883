import React from 'react';
import _ from 'lodash';

function Separator(props) {
  const normalDeg = -(props.deg);

  return (
    <div
      className='separator'
      style={{
        position: 'absolute',
        height: '120%',
        transform: `rotate(${props.deg}deg)`
      }}>
      <div
        className='label'
        style={{
          transform: `rotate(${normalDeg}deg)`
        }}>
        {props.index}
      </div>
    </div>
  );
}

function RadialLabel(props) {
  const deg = (180 / props.count);
  return (
    <>
      {_.range(props.count).map((index) => (
        <Separator key={index} deg={(index * deg) - 90} index={index} />
      ))}
      <Separator deg={90} index={props.count} />
    </>
  );
}

export default RadialLabel;
