import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { SatCoreComponent, SatCoreRegister } from 'sat-core';
import {
  Button,
  Container,
  Dropdown,
  Grid,
  Input,
  Segment
} from 'semantic-ui-react';
import '../css/AssignmentFilter.less';

export default
@inject('userManager', 'assignmentManager', 'filterManager')
@observer
class AssignmentFilter extends Component {
  constructor(props) {
    super(props);
    this.SCRadio = SatCoreComponent('SCRadio');
  }

  async componentDidMount() {
    await this.props.filterManager.fetchFilters();
    await this.fetchSearch('', '', '', '');
  }

  resetFilters = async () => {
    const { filterManager } = this.props;

    await this.resetSearch('', '', '', '');
    filterManager.setFilter({ stateId: '', institutionId: '', courseContentItemIds: [], search: '' });
  }

  handleChangeCourse = async (event, { value }) => {
    const { filterManager } = this.props;

    this.resetSearch(filterManager.currentFilter.stateId, filterManager.currentFilter.institutionId, value, filterManager.currentFilter.search);
    filterManager.setFilter({ courseContentItemIds: value });
  }

  handleChangeInstitution = async (event) => {
    const { filterManager } = this.props;

    const { value } = event.target;
    this.resetSearch(filterManager.currentFilter.stateId, value, filterManager.currentFilter.courseContentItemIds, filterManager.currentFilter.search);
    filterManager.setFilter({ institutionId: value });
  }

  handleChangeState = async (event) => {
    const { filterManager } = this.props;

    const { value } = event.target;
    this.resetSearch(value, '', filterManager.currentFilter.courseContentItemIds, filterManager.currentFilter.search);
    filterManager.setFilter({ stateId: value, institutionId: '' });
    await this.props.filterManager.fetchFilters();
  }

  handleChangeSearch = async (event, { value }) => {
    const { filterManager } = this.props;

    if (value.length > 2 || value.length === 0) {
      this.resetSearch(filterManager.currentFilter.stateId, filterManager.currentFilter.institutionId, filterManager.currentFilter.courseContentItemIds, value);
    }
    filterManager.setFilter({ search: value });
  }

  resetSearch = async (stateId, institutionId, courseContentItemIds, search) => {
    await this.fetchSearch(stateId, institutionId, courseContentItemIds, search);
  }

  fetchSearch = async (stateId, institutionId, courseContentItemIds, search) => {
    const { assignmentManager } = this.props;
    assignmentManager.fetchLplAssignments(stateId, institutionId, courseContentItemIds, search);
  }

  renderStateRadios = () => {
    const { filterManager } = this.props;
    const { SCRadio } = this;
    const stateComponentArray = [];

    const selectedState = filterManager.currentFilter.stateId;
    const selectedInstitution = filterManager.currentFilter.institutionId;

    for (let i = 0; i < filterManager.stateArray.length; i++) {
      const institutionComponentArray = [];
      if (filterManager.institutionArray.length > 0 && selectedState === filterManager.stateArray[i].id) {
        for (let x = 0; x < filterManager.institutionArray.length; x++) {
          institutionComponentArray.push(<SCRadio
            key={`${filterManager.institutionArray[x].id}_R`}
            checked={selectedInstitution === filterManager.institutionArray[x].id}
            label={filterManager.institutionArray[x].name}
            name='institutionradio'
            onChange={this.handleChangeInstitution}
            value={filterManager.institutionArray[x].id} />);
        }
      }

      stateComponentArray.push(<Fragment key={`${filterManager.stateArray[i].id}_R`}>
        <SCRadio
          checked={selectedState === filterManager.stateArray[i].id}
          label={filterManager.stateArray[i].name}
          name='stateRadio'
          onChange={this.handleChangeState}
          value={filterManager.stateArray[i].id} />
        <div className='institution-radios'>
          {(institutionComponentArray.length > 0) ? institutionComponentArray : null}
        </div>
      </Fragment>);
    }
    return stateComponentArray;
  }

  render() {
    const { filterManager } = this.props;

    const { SCRadio } = this;

    const options = filterManager.courseContentArray.map((course, index) => ({
      key: course.id + index,
      text: course.name,
      value: course.id
    }));

    return (

      <Segment className='filter'>
        <Grid>
          <Grid.Column>
            <Input
              icon='search'
              name='search'
              onChange={this.handleChangeSearch}
              placeholder='Enter content name'
              type='text'
              value={filterManager.currentFilter.search} />
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column>
            <div className='filter-row'>
              <div className='row-elem'>
                <h3>State</h3>
              </div>
              <div className='row-elem'>
                <Button className='reset-link' onClick={this.resetFilters}> Reset Filters</Button>
              </div>
            </div>
            <Container>
              {
                (filterManager.stateArray.length > 0) ? (
                  <SCRadio
                    key='all_R'
                    checked={filterManager.currentFilter.stateId === '' || filterManager.currentFilter.stateId === null}
                    label='All'
                    name='stateRadio'
                    onChange={this.handleChangeState}
                    value='' />
                ) : null
              }
              <>
                {(filterManager.stateArray.length > 0) ? this.renderStateRadios() : null}
              </>
            </Container>
            <div className='filter-content-type-section'>
              <h3>Courses</h3>
              <Container>
                {
                  (filterManager.courseContentArray) ? (
                    <Dropdown
                      clearable
                      fluid
                      multiple
                      onChange={this.handleChangeCourse}
                      options={options}
                      placeholder='Course'
                      search
                      selection
                      // using slice because mobx arrays are not js arrays and slice guarantees a js array
                      value={filterManager.currentFilter.courseContentItemIds.slice()} />
                  ) : null
                }
              </Container>
            </div>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
}

SatCoreRegister('AssignmentFilter', AssignmentFilter);
