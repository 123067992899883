const translations =
{
  Gradebook: {
    gradebookBR: 'Details'
  },
  GradebookActivityTable: {
    feedBack: 'Shoutout',
    firstName: 'First Name',
    grade: 'Grade',
    lastName: 'Last Name',
    status: 'Status',
    studentVersion: 'Student Version',
    submitted: 'Submitted'
  },
  GradebookTable: {
    feedBack: 'Shoutout'
  },
  StudentFeedbackModal: {
    feedBack: 'Shoutout'
  },
  TeacherFeedbackModal: {
    feedBack: 'Shoutout'
  }
};

export default translations;
