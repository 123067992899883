import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import ReactHtmlParser from 'react-html-parser';
import { Icon, Segment } from 'semantic-ui-react';
import { ASSIGNMENT_STATUS, CONTENT_ITEM_TYPES, CONTENT_MODE, DIALOG_NAMES, VIEW_SELECTION } from 'sat-core';
import PlayerService from '../services/PlayerService';
import '../css/ActionCard.less';

export default
@inject('dialogManager', 'courseManager', 'tagContentManager', 'userManager', 'progressManager')
@observer
class ActionCard extends Component {
  handleClick = async () => {
    const { courseElement, userManager } = this.props;

    let studentActivity = null;
    if (courseElement !== null && courseElement !== undefined && courseElement.studentActivityInstances.length > 0) {
      studentActivity = courseElement.studentActivityInstances[0];
    }
    if (studentActivity === null || studentActivity === undefined) {
      return;
    }
    if (studentActivity.status === ASSIGNMENT_STATUS.READY ||
        studentActivity.status === ASSIGNMENT_STATUS.STARTED) {
      if (courseElement.activity.status === ASSIGNMENT_STATUS.CLOSED) {
        return;
      }
      await PlayerService.launchPlayerFromAssignment(courseElement.activity, studentActivity, CONTENT_MODE.ASSESS);
    }
    if (studentActivity.status === ASSIGNMENT_STATUS.CLOSED) {
      await PlayerService.launchPlayerFromAssignment(courseElement.activity, studentActivity, (userManager.isStudent) ? CONTENT_MODE.REVIEW : CONTENT_MODE.TEACHER_REVIEW_AS_STUDENT);
    }
  }

  handleReview = async () => {
    const { courseElement } = this.props;

    await PlayerService.launchPlayerFromCourseElement(courseElement, CONTENT_MODE.PREVIEW);
  }

  handleGradebook = () => {
    const { courseElement } = this.props;
    let studentActivity = null;
    if (courseElement !== null && courseElement !== undefined && courseElement.studentActivityInstances.length > 0) {
      studentActivity = courseElement.studentActivityInstances[0];
    }
    if (studentActivity === null || studentActivity === undefined) {
      return;
    }
    const { activityId } = studentActivity;

    if (courseElement.type === CONTENT_ITEM_TYPES.LESSON) {
      this.props.history.push(`/gradebook_activity?assignmentId=${activityId}&view=${VIEW_SELECTION.GRADEBOOK}`);
    } else if (courseElement.type === CONTENT_ITEM_TYPES.ASSESSMENT) {
      this.props.history.push(`/gradebook?assignmentId=${activityId}&view=${VIEW_SELECTION.GRADEBOOK}`);
    }
  }

  handleStandards = async () => {
    const { courseElement, dialogManager, courseManager } = this.props;
    const { name } = courseElement;
    const standards = await courseManager.fetchAlignmentsGroupedByDocument(courseElement.contentItemId, courseElement.courseResourceId);

    dialogManager.setOpenDialog(DIALOG_NAMES.STANDARDS_DOCUMENTS, {
      lessonName: name,
      standards,
    }, this.closeTagsDialog);
  }

  closeTagsDialog = () => {
    const { dialogManager } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.STANDARDS_DOCUMENTS);
  }

  getClass = () => {
    const { courseElement } = this.props;

    let studentActivity = null;
    if (courseElement !== null && courseElement !== undefined && courseElement.studentActivityInstances.length > 0) {
      studentActivity = courseElement.studentActivityInstances[0];
    }
    if (studentActivity === null || studentActivity === undefined) {
      return;
    }

    const { status } = studentActivity;

    if (status === ASSIGNMENT_STATUS.READY) {
      return 'ready';
    } else if (status === ASSIGNMENT_STATUS.STARTED) {
      return ('started');
    }

    return 'ready';
  }

  isOpen = (courseElement) => {
    // const { tagContentManager } = this.props;
    if (courseElement !== null) {
      return true;
    }

    return true;

    // this feature has been removed by the customer until further notice
    // leave this function in so the feature can be turned on when needed.

    /* const tag = tagContentManager.getCardContentTagName( courseElement.contentItemId );
    return ((courseElement.type === CONTENT_ITEM_TYPES.LESSON && tag === 'Optional' &&  courseElement.open)
      || (courseElement.type === CONTENT_ITEM_TYPES.LESSON && tag === 'Required')
      || (courseElement.type === CONTENT_ITEM_TYPES.ASSESSMENT )); */
  }

  renderStatus = () => {
    const { courseElement } = this.props;
    const { activity } = courseElement;
    let studentActivity = null;

    const isOpen = this.isOpen(courseElement);

    if (courseElement !== null && courseElement !== undefined && courseElement.studentActivityInstances.length > 0) {
      studentActivity = courseElement.studentActivityInstances[0];
    }

    if (studentActivity === null || studentActivity === undefined) {
      return null;
    }

    const { status } = studentActivity;

    // the main activity is closed but the current user never finished the assignment set to complete
    // and allow the user review mode
    if (activity === null) {
      return;
    }
    if ((activity.status === ASSIGNMENT_STATUS.CLOSED || activity.status === ASSIGNMENT_STATUS.COMPLETED) &&
      (status === ASSIGNMENT_STATUS.READY || status === ASSIGNMENT_STATUS.STARTED)) {
      return (
        <div className='status-wrapper'>
          <div className='action-status completed' onClick={this.handleReview}>Completed</div>
        </div>
      );
    } else if (status === ASSIGNMENT_STATUS.READY) {
      return (
        <div className='status-wrapper'>
          {(isOpen) ? (
            <>
              <div className='action-status ready' onClick={this.handleClick}>Start Activity</div>
              <Icon className=' started' name='arrow right' />
            </>
          ) : (
            <>
              <div className='action-status ready closed'>Start Activity</div>
              <Icon className=' started closed' name='arrow right' />
            </>
          )}
        </div>
      );
    } else if (status === ASSIGNMENT_STATUS.STARTED) {
      return (
        <div className='status-wrapper'>
          {(isOpen) ? (
            <>
              <div className='action-status started' onClick={this.handleClick}>View Progress</div>
              <Icon className=' started' name='arrow right' />
            </>
          ) : (
            <>
              <div className='action-status ready closed'>Start Activity</div>
              <Icon className=' started closed' name='arrow right' />
            </>
          )}
        </div>
      );
    }
    return (
      <div className='status-wrapper'>
        <div className='action-status completed' onClick={this.handleClick}>Completed</div>
      </div>
    );
  }

  render() {
    const { courseElement, tagContentManager, isTeacher } = this.props;
    const { name } = courseElement;
    const tag = tagContentManager.getCardContentTagName(courseElement.contentItemId);

    const isOpen = this.isOpen(courseElement);

    return (
      <Segment className='action-card-satcore list-card'>
        <div className='card-body'>
          <div className={(isOpen) ? `action-name ${this.getClass()}` : 'action-name'} onClick={(isOpen) ? this.handleClick : null}>{ReactHtmlParser(name)}</div>
          <div className='action-footer'>
            {(courseElement.type === CONTENT_ITEM_TYPES.LESSON && tag === 'Optional') ? (
              <div className='action-option optional'>
                <Icon name='star' />
                {' '}
                Optional
              </div>
            ) :
              (courseElement.type === CONTENT_ITEM_TYPES.LESSON && tag === 'Required') ? (
                <div className='action-option required'>
                  <Icon name='circle' />
                  {' '}
                  Required
                </div>
              ) :
                null}
            {(courseElement.type === CONTENT_ITEM_TYPES.LESSON) ? (
              <div className='action-points'>
                {courseElement.contentItemMaxScore}
                {' '}
                Points
              </div>
            ) :
              null}
            {(isTeacher) ? <div className='action-gradebook' onClick={this.handleGradebook}>Details</div> : null}
            {(isTeacher && courseElement.hasAlignments) &&
              <div className='action-standards-pill' onClick={this.handleStandards}>Standards</div>}
          </div>
        </div>
        <>
          {this.renderStatus()}
        </>
      </Segment>
    );
  }
}
