import React, { Component } from 'react';
import { ASSIGNMENT_STATUS, CONTENT_MODE, DIALOG_NAMES, Modal, SatCoreComponent, SatCoreRegister } from 'sat-core';
import { inject, observer } from 'mobx-react';
import { Button, Icon, Image, Table } from 'semantic-ui-react';
import iconCommentBlue from '../img/icon-comment-blue.svg';
import iconCommentNone from '../img/icon-comment-none.svg';

import '../css/GradebookActivity.less';
import PlayerService from '../services/PlayerService';

export default

@inject('assignmentManager', 'userManager', 'courseManager',
  'gradebookManager', 'contentManager',
  'dialogManager', 'progressManager')
@observer
class GradebookTable extends Component {
  state = {
    studentName: '',
    column: 'lastName',
    direction: 'ascending',
    sendDialogOpen: false,
    switchDialogOpen: false,
    activityInstanceId: null

  }

  constructor(props) {
    super(props);
    this.AssignmentStatus = SatCoreComponent('AssignmentStatus');
  }

  getButton = (instance) => {
    const { currentAssignment } = this.props.gradebookManager;

    if (currentAssignment.status === ASSIGNMENT_STATUS.CLOSED || currentAssignment.status === ASSIGNMENT_STATUS.COMPLETED) {
      if (instance.remotelyScored === true) {
        return (
          <Button className='sent' onClick={() => this.openSwitch(instance)}>
            <Icon name='check' />
&nbsp;sent
          </Button>
        );
      }
      return (<></>);
    }
    if (instance.status === ASSIGNMENT_STATUS.CLOSED) {
      return (<Button basic onClick={() => this.openSend(instance)} primary>Send</Button>);
    }
    return (<></>);
  }

  openActivity = async (instance) => {
    const { progressManager } = this.props;

    const result = await progressManager.openActivity(instance.id);
    if (result) {
      await this.resetTable();
    }
  }

  getOpenButton = (instance) => {
    const { currentAssignment } = this.props.gradebookManager;

    const showButton = ((currentAssignment.status === ASSIGNMENT_STATUS.READY || currentAssignment.status === ASSIGNMENT_STATUS.STARTED) &&
      (instance.status === ASSIGNMENT_STATUS.CLOSED));

    return (
      <>
        {(showButton) ? <div className='view-link clickable blue' onClick={() => this.openActivity(instance)}>Re-assign</div> : null}
      </>
    );
  }

  launchFeedback = (instance) => {
    const { dialogManager } = this.props;
    const studentName = `${instance.firstName} ${instance.lastName}`;

    dialogManager.setOpenDialog(DIALOG_NAMES.TEACHER_FEEDBACK, {
      assignmentInstanceId: instance.id,
      studentName
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.TEACHER_FEEDBACK)
    );
  }

  handleSort = (clickedColumn) => () => {
    const { column, direction } = this.state;
    const { gradebookManager } = this.props;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        direction: 'ascending'
      });

      gradebookManager.sortAssignmentInstances(clickedColumn);
      return;
    }
    gradebookManager.reverseAssignmentInstances();
    this.setState({
      direction: direction === 'ascending' ? 'descending' : 'ascending'
    });
  }

  resetTable = async () => {
    const { gradebookManager, courseManager } = this.props;
    const assignment = await gradebookManager.fetchGradebookData(gradebookManager.currentAssignment.id);

    courseManager.clearData();

    if (assignment != null) {
      gradebookManager.setCurrentAssignment(assignment);
    }
  }

  confirmSendToLpl = async () => {
    const { activityInstanceId } = this.state;
    const { progressManager } = this.props;

    if (activityInstanceId !== null) {
      const result = await progressManager.sendToLPLAdmin(activityInstanceId);
      if (result) {
        await this.resetTable();
      }
    }
    this.closeSendDialog();
  }

  confirmSwitchToLpl = async () => {
    const { activityInstanceId } = this.state;
    const { progressManager } = this.props;

    if (activityInstanceId !== null) {
      const result = await progressManager.clearToLPLAdmin(activityInstanceId);
      if (result) {
        await this.resetTable();
      }
    }
    this.closeSwitchDialog();
  }

  openSend =(instance) => {
    const studentName = `${instance.firstName} ${instance.lastName}`;
    this.setState({ sendDialogOpen: true, studentName, activityInstanceId: instance.id });
  }

  closeSendDialog = () => {
    this.setState({ sendDialogOpen: false, studentName: '', activityInstanceId: null });
  }

  openSwitch =(instance) => {
    const studentName = `${instance.firstName} ${instance.lastName}`;
    this.setState({ switchDialogOpen: true, studentName, activityInstanceId: instance.id });
  }

  closeSwitchDialog = () => {
    this.setState({ switchDialogOpen: false, studentName: '', activityInstanceId: null });
  }

  launchPlayer = async (instance) => {
    const { gradebookManager } = this.props;

    await PlayerService.launchPlayerFromAssignment(gradebookManager.currentAssignment, instance, CONTENT_MODE.TEACHER_REVIEW_AS_STUDENT);
  }

  renderRows = (assignmentInstances) => {
    const { AssignmentStatus } = this;

    return (
      <>
        {assignmentInstances.map((instance, index) => (
          <Table.Row key={`tra_${instance.id}`}>
            <Table.Cell>{index + 1}</Table.Cell>
            <Table.Cell>{instance.firstName}</Table.Cell>
            <Table.Cell>{instance.lastName}</Table.Cell>
            <Table.Cell><AssignmentStatus noLabel={true} status={instance.status} /></Table.Cell>
            <Table.Cell className='re-assign-cell' textAlign='left'>{this.getOpenButton(instance)}</Table.Cell>
            <Table.Cell textAlign='center'>{(instance.submitted) ? <div>{instance.timezoneSubmittedDate}</div> : <div>—</div>}</Table.Cell>
            <Table.Cell textAlign='center'>{(instance.submitted || instance.status === ASSIGNMENT_STATUS.CLOSED) ? <div className='view-link clickable blue' onClick={() => this.launchPlayer(instance)}>View</div> : <div>—</div>}</Table.Cell>
            <Table.Cell textAlign='center'>
              {this.getButton(instance)}
              {' '}
            </Table.Cell>
            <Table.Cell textAlign='center'><Image className='feedback clickable' onClick={() => this.launchFeedback(instance)} src={(instance.teacherFeedback) ? iconCommentBlue : iconCommentNone} /></Table.Cell>
          </Table.Row>
        )
        )}
      </>
    );
  }

  render() {
    const { column, direction } = this.state;
    const { gradebookManager, t } = this.props;
    const { assignmentInstances } = gradebookManager;

    return (
      <>
        <Table sortable striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell
                onClick={this.handleSort('firstName')}
                sorted={column === 'firstName' ? direction : null}>
                {t('firstName', 'Missing view or preview translation')}
              </Table.HeaderCell>
              <Table.HeaderCell
                onClick={this.handleSort('lastName')}
                sorted={column === 'lastName' ? direction : null}>
                {t('lastName', 'Missing view or preview translation')}
              </Table.HeaderCell>
              <Table.HeaderCell
                className='status-header'
                onClick={this.handleSort('status')}
                sorted={column === 'status' ? direction : null}>
                Status
              </Table.HeaderCell>
              <Table.HeaderCell />
              <Table.HeaderCell
                onClick={this.handleSort('timezoneSubmittedDate')}
                sorted={column === 'timezoneSubmittedDate' ? direction : null}
                textAlign='center'>
                {t('submitted', 'Missing view or preview translation')}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>{t('studentVersion', 'Missing view or preview translation')}</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>
                Send to LPL
                <br />
                <span className='sub-header'>1 Student Per Class</span>
              </Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>{t('feedBack', 'Missing view or preview translation')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.renderRows(assignmentInstances)}
          </Table.Body>
        </Table>
        <Modal className='send-modal' onClose={this.closeSendDialog} open={this.state.sendDialogOpen} size='tiny'>
          <Modal.Header>Send to LPL</Modal.Header>
          <Modal.Content>
            <p>{`Send ${this.state.studentName} to LPL for review.`}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button basic onClick={this.closeSendDialog} primary type='button'>Cancel</Button>
            <Button onClick={this.confirmSendToLpl} primary type='button'>Send</Button>
          </Modal.Actions>
        </Modal>
        <Modal className='send-modal' onClose={this.closeSwitchDialog} open={this.state.switchDialogOpen} size='tiny'>
          <Modal.Header>Sent to LPL: Modify</Modal.Header>
          <Modal.Content>
            <p>{`${this.state.studentName} has been sent to LPL for review. Would you like to change the student? Changing will remove the student from the LPL’s queue.`}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button basic onClick={this.closeSwitchDialog} primary type='button'>Keep</Button>
            <Button onClick={this.confirmSwitchToLpl} primary type='button'>Change</Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

SatCoreRegister('GradebookActivityTable', GradebookTable);
