import React from 'react';
import { Icon, Segment } from 'semantic-ui-react';
import ReactHtmlParser from 'react-html-parser';
import Trophy from './Trophy';
import Statusbar from './Statusbar';
import '../css/StatusCard.less';

const StatusCard = (props) => {
  const { element } = props;

  return (
    <Segment className='summary-card'>
      <div className='trophy-wrapper'>
        <Trophy color={element.trophy} />
      </div>
      <div className='card-body'>
        <div className='card-title'>
          <span>{ReactHtmlParser(element.courseResourceElementName)}</span>
          {(element.courseResourceElementDescription !== '' && element.courseResourceElementDescription !== null) ? (
            <>
              <span>:</span>
              <span>{ReactHtmlParser(element.courseResourceElementDescription)}</span>
            </>
          )
            : null}
        </div>
        <div className='row-two'>
          <div className='bar-wrapper'>
            <Statusbar element={element} />
          </div>
          <div className='detail-wrapper'>
            <div className='action-status started' onClick={() => props.handleClick(element.courseId, element.courseResourceElementId, element.courseResourceElementName)}>VIEW BENCHMARK</div>
            <Icon className='started' name='arrow right' />
          </div>
        </div>
        <div className='row-three'>
          <div className='quizzes-field'>
            <div className='quizzes-text'>Quizzes Completed:</div>
            <div className='quizzes-value'>
              {element.completedQuizzes}
              /
              {element.totalQuizzes}
            </div>
          </div>
          <div className='quizzes-field'>
            <div className='quizzes-text'>Quizzes Average:</div>
            <div className='quizzes-value'>
              {element.quizAverage}
              %
            </div>
          </div>
          <div className='quizzes-field'>
            <div className='quizzes-text'>Activity Tracker:</div>

            <div className='quizzes-field'>
              <div className='quizzes-subtext'>Required:</div>
              <div className='quizzes-value-subtext'>
                {element.completedRequiredActivities}
                /
                {element.totalRequiredActivities}
              </div>
            </div>
            <div className='quizzes-field'>
              <div className='quizzes-subtext'>Optional:</div>
              <div className='quizzes-value-subtext wide'>
                {element.completedOptionalActivities}
                /
                {element.totalOptionalActivities}
              </div>
            </div>
          </div>
          <div className='quizzes-field'>
            <div className='quizzes-text'>Points Earned:</div>
            <div className='quizzes-value'>
              {element.earnedPoints}
              /
              {(element.totalPoints === null) ? 0 : element.totalPoints}
            </div>
          </div>
        </div>
      </div>
    </Segment>
  );
};

export default StatusCard;
