import ReactHtmlParser from 'react-html-parser';
import { Button, Icon, Popup, Segment } from 'semantic-ui-react';
import React, { Component } from 'react';
import PlayerService from '../services/PlayerService';
import '../css/RootLayout.less';

export default class TeacherTrainingCard extends Component {
  handleClickTeacherPreview = (courseElement) => {
    PlayerService.launchPlayerFromCourseElement(courseElement);
  }

  render() {
    const { courseElement } = this.props;

    return (
      <Segment
        className='branch-card-satcore list-card'>
        <div className='card-body'>
          <Popup
            content={courseElement.name}
            trigger={(
              <div
                className='branch-name clickable'
                onClick={() => {
                  this.handleClickTeacherPreview(courseElement);
                }}>
                <span>{ReactHtmlParser(courseElement.name)}</span>
              </div>
)} />
        </div>
        <div className='card-nav-buttons'>
          <Button onClick={() => {
            this.handleClickTeacherPreview(courseElement);
          }}>
            <Icon className='arrow-right' />
          </Button>
        </div>
      </Segment>
    );
  }
}
