import { action, makeObservable, observable } from 'mobx';
import { Auth } from 'sat-core';

export const BACKGROUND_CLASSES = {
  LOGIN: 'login-page',
  SEPTEMBER: 'sep',
  OCTOBER: 'oct',
  NOVEMBER: 'nov',
  DECEMBER: 'dec',
  JANUARY: 'jan',
  FEBRUARY: 'feb',
  MARCH: 'march',
  APRIL: 'april',
  MAY: 'may',
  JUNE: 'june'
};

export class BackgroundImageManager {
  @observable currentImageClass = BACKGROUND_CLASSES.LOGIN;

  @observable currentImage = null;

  constructor() {
    makeObservable(this);
  }

  @action setCurrentImageClass = (className) => {
    this.currentImageClass = className;
  }

  getCurrentImageUrl = () => this.currentImage

  @action setCurrentImage = (image) => {
    this.currentImage = image;
  }

  getSectionImage = async (elementId) => {
    let response = null;

    try {
      response = await Auth.fetch(`${Auth.ecms}/api/viewCourseResourceElement?elementId=${elementId}`, {
        method: 'GET'
      });
    } catch (e) {
      console.log(e);
    }

    if (response && response.status === 'SUCCESS') {
      const elements = response.courseResourceElements;
      if (elements.length > 0 && elements[0]) {
        return elements[0].attachmentContentItemId;
      }
    }
    return null;
  }
}

export default new BackgroundImageManager();
