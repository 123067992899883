import React, { Component } from 'react';
import { Auth, SatCoreComponent, SatCoreRegister, VIEW_SELECTION, navigationManager } from 'sat-core';
import { Redirect } from 'react-router-dom';
import {
  Button,
  Form,
  Grid,
  Header,
  Input,
  Message,
  Segment
} from 'semantic-ui-react';

import '../css/Login.less';

const ERROR_MESSAGES = {
  GENERAL: 'Something has gone wrong with your registraition.  Please check your information and try again.  If you think everything is correct, please contact your administraitor.',
  ACCESS_CODE_INVALID: 'This access code does not work. Please check your code and try again.',
  PASSWORD_MATCH: 'Passwords do not match.',
  PASSWORD_LENGTH: 'Passwords must be at least 5 characters long.',
  VALID_EMAIL_FORMAT: 'Please enter a valid email format. (name@example.com)',
  USER_EXISTS: 'There is already a user with that email address assigned to that access code.  Try resetting your password.  If the problem persists contact your administraitor.'
};

export default class Register extends Component {
  teacherRegisterSuccessMessage = 'Registration complete, check your email to verify your account.';

  publisherSatelliteCode = Auth.publisherSatelliteCode;

  constructor(props) {
    super(props);

    this.state = {
      passwordInputType: 'password',
      passwordInputType2: 'password',
      accessCode: {
        value: '',
        error: null,
        result: null
      },
      firstName: {
        value: '',
        error: null
      },
      lastName: {
        value: '',
        error: null
      },
      email: {
        value: '',
        error: null
      },
      password: {
        value: '',
        error: null
      },
      confirmPassword: {
        value: '',
        error: null
      },
      submitted: false,
      success: false,
      student: false,
      username: '',
      formError: false
    };
    this.Logo = SatCoreComponent('Logo');
    this.ShowPasswordButton = SatCoreComponent('ShowPasswordButton');
  }

  componentDidMount() {
    navigationManager.setView(VIEW_SELECTION.LOGIN);
  }

  handleShowPasswordPressed = (name, isPasswordInput) => {
    const inputType = isPasswordInput ? 'password' : 'text';
    this.setState({ [name]: inputType });
  }

  render() {
    if (Auth.loggedIn()) {
      return <Redirect to='/' />;
    }
    const { Logo } = this;
    const { ShowPasswordButton } = this;

    return (
      <Grid className='login-body' textAlign='center' verticalAlign='middle'>
        <Grid.Column  className="max-width-558">
          <Form>
            <Header as='h2' attached='top' block>
              <Logo />
              <span className='header-text'>
                Self Registration
                {this.state.success ? 'Success' : null}
              </span>
            </Header>
            {this.state.success
              ? (
                <Segment attached className='element-body-login'>
                  {
                this.state.student
                  ? <p><Button basic className='homepage' onClick={this.handleAutoLogin} primary>Take me to my home page</Button></p>
                  : <p>{this.teacherRegisterSuccessMessage}</p>
}
                </Segment>
              )
              : (
                <Segment attached className='element-body-login'>
                  <Form.Field>
                    <Form.Input
                      label='Access Code'
                      name='accessCode'
                      onChange={this.handleChange}
                      onFocus={this.clearError}
                      placeholder='x-digits'
                      type='text' />
                    <Message
                      content={this.state.accessCode.error}
                      error
                      visible={this.state.accessCode.error !== null} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input
                      label='First Name'
                      name='firstName'
                      onChange={this.handleChange}
                      onFocus={this.clearError}
                      placeholder='First Name'
                      type='text' />
                  </Form.Field>

                  <Form.Field>
                    <Form.Input
                      label='Last Name'
                      name='lastName'
                      onChange={this.handleChange}
                      onFocus={this.clearError}
                      placeholder='Last Name'
                      type='text' />
                  </Form.Field>

                  <Form.Field>
                    <Form.Input
                      label='Username/ email'
                      name='email'
                      onChange={this.handleChange}
                      onFocus={this.clearError}
                      placeholder='name@email.com'
                      type='email' />

                    <Message
                      content={this.state.email.error}
                      error
                      visible={this.state.email.error !== null} />
                  </Form.Field>
                  <Form.Field>
                    <label>Enter your password</label>
                    <Input
                      autoComplete='current-password'
                      label={(
                        <ShowPasswordButton
                          isPassword={(this.state.passwordInputType === 'password')}
                          name='passwordInputType'
                          onFlip={this.handleShowPasswordPressed} />
                    )}
                      labelPosition='right'
                      name='password'
                      onChange={this.handleChange}
                      onFocus={this.clearError}
                      onKeyPress={(e) => e.stopPropagation()}
                      placeholder='Enter password'
                      type={this.state.passwordInputType} />
                    <div className='note'>(Passwords are required to be at least 5 characters long.)</div>
                  </Form.Field>
                  <Message
                    content={this.state.password.error}
                    error
                    visible={this.state.password.error !== null} />

                  <Form.Field>
                    <label>Confirm your password</label>
                    <Input
                      autoComplete='current-password'
                      label={(
                        <ShowPasswordButton
                          isPassword={(this.state.passwordInputType2 === 'password')}
                          name='passwordInputType2'
                          onFlip={this.handleShowPasswordPressed} />
                    )}
                      labelPosition='right'
                      name='confirmPassword'
                      onChange={this.handleChange}
                      onFocus={this.clearError}
                      onKeyPress={(e) => e.stopPropagation()}
                      placeholder='Confirm password'
                      type={this.state.passwordInputType2} />
                    <Message
                      content={this.state.confirmPassword.error}
                      error
                      visible={this.state.confirmPassword.error !== null} />
                  </Form.Field>

                  <Form.Field>
                    <Button disabled={this.state.submitted || !this.isComplete()} onClick={this.handleFormSubmit} primary>
                      Submit
                    </Button>
                    <Message
                      content={ERROR_MESSAGES.GENERAL}
                      error
                      visible={this.state.formError} />
                  </Form.Field>
                </Segment>
              )}
          </Form>
        </Grid.Column>
      </Grid>
    );
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => {
      const newState = { ...prevState };
      newState[name].value = value;
      return newState;
    });
  }

  clearError = (e) => {
    const { name } = e.target;
    this.setState((prevState) => {
      const newState = { ...prevState };
      newState[name].error = null;
      return newState;
    });
  }

  validate = async () => {
    let valid = true;

    const validCode = await this.validateAccessCode();
    if (validCode === false) {
      valid = false;
    }

    if (this.state.email.value) {
      const result = await this.validateUser();
      if (result === false) {
        valid = false;
      }
    }

    if (this.state.student) {
      const email = { ...this.state.email };
      email.error = null;
      this.setState({ email });

      if (!this.state.accessCode.error &&
           this.state.accessCode.result) {
        // we must validate if the user already exists only if the
        // user entered a valid access code already.  If needed We will
        // check again when the user does enter a valid code in the
        // 'accessCode' case above.
        const result = await this.validateUser();
        if (result === false) {
          valid = false;
        }
      }
    } else {
      if (!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.email.value))) {
        const email = { ...this.state.email };
        email.error = ERROR_MESSAGES.VALID_EMAIL_FORMAT;
        this.setState({ email });
        valid = false;
      } else {
        const email = { ...this.state.email };
        email.error = null;
        this.setState({ email });
        if (!this.state.accessCode.error &&
          this.state.accessCode.result) {
          // we must validate if the user already exists only if the
          // user entered a valid access code already.  If needed We will
          // check again when the user does enter a valid code in the
          // 'accessCode' case above.
          const result = await this.validateUser();
          if (result === false) {
            valid = false;
          }
        }
      }
    }
    const { password, confirmPassword } = this.state;
    if (password.value !== confirmPassword.value) {
      confirmPassword.error = ERROR_MESSAGES.PASSWORD_MATCH;
      valid = false;
    } else {
      confirmPassword.error = null;
    }
    if (password.value !== null && password.value.length > 0 && password.value.length < 5) {
      password.error = ERROR_MESSAGES.PASSWORD_LENGTH;
      valid = false;
    } else {
      password.error = null;
    }
    this.setState({ password, confirmPassword });

    return valid;
  }

  validateAccessCode = async () => {
    try {
      const accessCode = { ...this.state.accessCode };

      const data = await Auth.fetch(`${Auth.ecms}/api/validateSignUpCode`, {
        method: 'POST',
        body: {
          signupCode: accessCode.value,
          publisherSatelliteCode: Auth.publisherSatelliteCode
        }
      });

      if (data.codeValid && (data.codeType === 'classroom' || data.codeType === 'school' || data.codeType === 'publisher')) {
        // classroom access code type can be "classroom" for co-teacher or student so have to check the category now too.
        if (data.codeType === 'classroom' && data.codeCategory === 'classroom') {
          this.setState({
            student: true
          });
        } else {
          this.setState({
            student: false
          });
        }

        accessCode.result = data;
        accessCode.error = null;
      } else {
        accessCode.result = data;
        accessCode.error = ERROR_MESSAGES.ACCESS_CODE_INVALID;
      }
      this.setState({ accessCode });
      if (accessCode.error !== null) {
        return false;
      }
      return true;
    } catch (error) {
      console.error(error);
    }
  }

  validateUser = async () => {
    const { accessCode } = this.state;
    const { result } = accessCode;
    const email = { ...this.state.email };
    try {
      const data = await Auth.fetch(`${Auth.ecms}/api/checkEmailAddress`, {
        method: 'POST',
        body: { publisherId: result.publisherId, emailAddress: email.value }
      });
      if (data.emailAddressExists) {
        email.error = ERROR_MESSAGES.USER_EXISTS;
      } else {
        email.error = null;
      }

      this.setState({ email });
      if (email.error !== null) {
        return false;
      }
      return true;
    } catch (error) {
      console.log(error);
    }
  }

  isComplete = () => !!(this.state.accessCode.value.length &&
            !this.state.accessCode.error &&
            this.state.firstName.value.length &&
            !this.state.firstName.error &&
            this.state.lastName.value.length &&
            !this.state.lastName.error &&
            this.state.email.value.length &&
            !this.state.email.error &&
            this.state.password.value.length &&
            !this.state.password.error &&
            this.state.confirmPassword.value.length &&
            !this.state.confirmPassword.error &&
            (this.state.password.value === this.state.confirmPassword.value)
  )

  handleAutoLogin = async () => {
    const loggedIn = await Auth.login(this.state.email.value, this.state.password.value);
    if (loggedIn) {
      this.props.history.replace('/');
    }
  }

  handleFormSubmit = async (e) => {
    e.preventDefault();
    const valid = await this.validate();
    if (!valid) {
      return;
    }
    this.setState({ submitted: true, formError: false });

    const accessCode = this.state.accessCode.result;

    let url = Auth.ecms;

    const body = {
      signUpCode: accessCode.signupCode,
      institutionId: accessCode.institutionId,
      password: this.state.password.value,
      firstName: this.state.firstName.value,
      lastName: this.state.lastName.value,
      publisherSatelliteCode: Auth.publisherSatelliteCode,
      username: this.state.email.value
    };

    // determine which signup url to call
    if (accessCode.codeType === 'school') {
      url += '/api/signupTeacher?addTeacher=true';
    } else if (accessCode.codeType === 'publisher') {
      url += '/api/signupPublisher';
    } else if (accessCode.codeType === 'classroom') {
      switch (accessCode.codeCategory) {
        case 'classroom':
          url += '/api/signupStudent?addTeacher=true';
          break;
        case 'coteacher':
          url += '/api/signupTeacher?addTeacher=true';
          break;
        default:
      }
    }

    try {
      const data = await Auth.fetch(url, {
        method: 'POST',
        body
      });

      // if the status is SUCCESS and there is an ID then new user registered
      if (data.status === 'SUCCESS') {
        this.setState({
          success: true,
          username: this.state.email.value
        });
      } else {
        this.setState({ formError: true, submitted: false });
      }
    } catch (error) {
      this.setState({ formError: true, submitted: false });
      console.log(error);
    }
  }
}

SatCoreRegister('Register', Register);
