import {
  ASSIGNMENT_STATUS, Auth, CONTENT_ITEM_TYPES,
  CONTENT_MODE, CourseService, DIALOG_NAMES,
  ImageService, NavigationService, PLAYER_TYPES, assignmentManager, classroomManager, contentManager,
  courseManager, dialogManager, tagContentManager, userManager
} from 'sat-core';

import playerManager from '../managers/PlayerManager';

export default class PlayerService {
  static handleGeneralMessage = (message) => {
    dialogManager.setOpenDialog(DIALOG_NAMES.TEXT, { message });
  }

  static hideIframe = () => {
    playerManager.setState({ lessonPlayerShowing: false });
    CourseService.initCourseData(true, true, false);
  }

  static hideModal = () => {
    playerManager.setState({
      fileViewerShowing: false,
      instruction: '',
      learnosityPlayerShowing: false
    });
    NavigationService.treeNavigationFunction(
      courseManager.currentCourseId,
      classroomManager.currentClassroomId,
      courseManager.currentElementId
    );
  }

  static hideIframeFromOuterClick = (event) => {
    if (event.origin === Auth.publisher && event.data === 'hideIframe') {
      playerManager.setState({ lessonPlayerShowing: false });
      window.removeEventListener('message', PlayerService.hideIframeFromOuterClick);
      CourseService.initCourseData(true, true, false);
    }
  }

  static launchPlayerFromCourseElement = async (courseElement, contentMode = CONTENT_MODE.PREVIEW, pdfFormatSelected = false) => {
    let option;
    const contentItemId = courseElement.entityId;
    const entityTypeId = courseElement.type;
    const { name } = courseElement;
    let { pdfDeliveryFormat } = courseElement;
    const { deliveryMode } = courseElement;

    // if the user selected a particular format, use that.
    if (pdfFormatSelected) {
      pdfDeliveryFormat = pdfFormatSelected;
    }

    if (deliveryMode && deliveryMode.includes('student') && userManager.isStudent) {
      option = await contentManager.getOptionsForStudentPreview(
        contentItemId, entityTypeId, pdfDeliveryFormat,
        origin, userManager.isTeacher, userManager.userId
      );
    } else {
      if (contentMode === CONTENT_MODE.PRESENT) {
        const currentAssignment = assignmentManager.getLastAddedAssignment();
        option = await contentManager.getOptionsForTeacherPresent(
          contentItemId, currentAssignment, entityTypeId, origin, userManager.isTeacher
        );
      } else {
        option = await contentManager.getOptionsForTeacherPreview(
          contentItemId, entityTypeId, pdfDeliveryFormat,
          origin, userManager.isTeacher, userManager.userId
        );
      }
    }

    const { playerType, viewUrl, isFlowpaper } = option;

    if (!userManager.isTeacher) {
      // TODO: IS STUDENT PREVIEW MODE FOR LESSON GOING TO BE SOMETHING? API RETURNS ONLY TEACHER PREVIEW WITHOUT ACTIVITY.
      if (playerType === PLAYER_TYPES.LEARNOSITY_PLAYER || playerType === PLAYER_TYPES.LESSON_PLAYER) {
        PlayerService.handleGeneralMessage('This type is unsupported.');
        return false;
      }
    }

    if (playerType === null && viewUrl !== null) {
      window.open(viewUrl, '_blank');
      return;
    }

    await contentManager.configPlayerWindow(playerType, window, PlayerService.hideIframeFromOuterClick);

    if (playerType === PLAYER_TYPES.CONTENT_PREVIEW_PLAYER) {
      dialogManager.setOpenDialog(DIALOG_NAMES.CONTENT_PREVIEW, {
        contentItemId,
        contentItemType: entityTypeId,
        resourceName: name
      }, () => dialogManager.closeDialog(DIALOG_NAMES.CONTENT_PREVIEW));
    }

    playerManager.setState({
      assignmentId: '',
      contentImageUrl: '',
      contentItemId,
      contentItemType: entityTypeId,
      contentName: name,
      contentUrl: viewUrl,
      fileViewerShowing: (playerType === PLAYER_TYPES.FILE_VIEWER),
      instruction: '',
      isFlowpaper,
      learnosityPlayerShowing: (playerType === PLAYER_TYPES.LEARNOSITY_PLAYER),
      lessonPlayerShowing: (playerType === PLAYER_TYPES.LESSON_PLAYER),
      previewContentItemId: '',
      previewContentType: '',
      resourceName: ''
    });
  }

  static launchPlayerFromAssignment = async (assignment, assignmentInstance, contentMode = CONTENT_MODE.ASSESS) => {
    let theAssignment = assignment;
    let theInstance = assignmentInstance;
    const theContentMode = contentMode;
    const { isTeacher } = userManager;
    let { userId } = userManager;

    if (theContentMode === CONTENT_MODE.TEACHER_REVIEW_AS_STUDENT) {
      userId = theInstance.userId;
    }

    if (assignment === null && assignmentInstance === null) {
      return;
    }

    if (assignment === null) {
      theAssignment = assignmentInstance;
    }
    if (assignmentInstance === null) {
      theInstance = assignment;
    }
    if (contentMode === CONTENT_MODE.ASSESS) {
      const preStartContentItemId = theInstance.contentItemId;
      if (ASSIGNMENT_STATUS.getCheckableStatuses().includes(theInstance.status)) {
        const isReady = await assignmentManager.checkActivity(theInstance.activityId);
        if (isReady) {
          const isOk = await assignmentManager.startActivity(theInstance.id, theInstance.activityId);
          if (isOk === false) {
            return false;
          }
        } else {
          return false;
        }
      }
      const postStartContentItemId = theInstance.contentItemId;
      if (preStartContentItemId !== postStartContentItemId) {
        // this has been versioned. make sure the new has an image.
        // console.log('*** A new version: ' + postStartContentItemId +' has been created from: '+ preStartContentItemId);
        tagContentManager.createNewCardContentTagFromOld(preStartContentItemId, postStartContentItemId);
      }
    }

    let option = null;
    // normalize some date between instance and parent activity
    if (theAssignment.entityTypeId === null || theAssignment.entityTypeId === undefined) {
      theInstance.contentItemEntityTypeId = theAssignment.contentItemEntityTypeId;
    } else {
      theInstance.contentItemEntityTypeId = theAssignment.entityTypeId;
    }

    if (contentMode === CONTENT_MODE.ASSESS) {
      if (userManager.isTeacher) {
        // we are working with an instance but present assumes and activity so a little short circit should fix it
        theInstance.id = theInstance.activityId;
        option = await contentManager.getOptionsForTeacherPresent(
          theInstance.contentItemId, theInstance, CONTENT_ITEM_TYPES.LESSON,
          window.location.origin, userManager.isTeacher
        );
      } else {
        option = await contentManager.getOptionsForStudentAssessment(
          theInstance, window.location.origin, userManager.isTeacher, userManager.userId
        );
      }
    } else if (contentMode === CONTENT_MODE.REVIEW && (!isTeacher && !userManager.isSatAdmin)) {
      option = await contentManager.getOptionsForStudentReview(
        theInstance, window.location.origin, isTeacher, userId
      );
    } else if (contentMode === CONTENT_MODE.TEACHER_REVIEW_AS_STUDENT && isTeacher) {
      option = await contentManager.getOptionsForStudentReview(
        theInstance, window.location.origin, isTeacher, userId, contentMode
      );
    } else if (contentMode === CONTENT_MODE.REVIEW && (isTeacher || userManager.isSatAdmin)) {
      option = await contentManager.getOptionsForTeacherReviewAndScoring(
        assignment, theInstance, window.location.origin, isTeacher
      );
    } else if (contentMode === CONTENT_MODE.PREVIEW && isTeacher) {
      option = await contentManager.getOptionsForTeacherPreviewAssigned(
        assignment, theInstance, window.location.origin, isTeacher
      );
    }
    const { playerType, viewUrl, isFlowpaper } = option;
    if (playerType === null && viewUrl !== null) {
      window.open(viewUrl, '_blank');
      return;
    }

    contentManager.configPlayerWindow(playerType, window, this.hideIframeFromOuterClick);

    const contentImageUrl = ImageService.getImageUrl(assignment);

    if (playerType === PLAYER_TYPES.CONTENT_PREVIEW_PLAYER) {
      dialogManager.setOpenDialog(DIALOG_NAMES.CONTENT_PREVIEW, {
        contentItemId: theAssignment.contentItemId,
        contentItemType: theAssignment.contentItemEntityTypeId,
        resourceName: theAssignment.resourceWebTitle || theAssignment.name
      }, () => dialogManager.closeDialog(DIALOG_NAMES.CONTENT_PREVIEW));

      return;
    }

    playerManager.setState({
      activityId: theAssignment.activityId,
      assignmentId: theAssignment.id,
      contentImageUrl,
      contentItemId: theAssignment.contentItemId,
      contentName: theAssignment.resourceWebTitle || theAssignment.name,
      contentUrl: viewUrl,
      fileViewerShowing: (playerType === PLAYER_TYPES.FILE_VIEWER),
      instruction: theAssignment.activityInstruction,
      isFlowpaper,
      learnosityPlayerShowing: (playerType === PLAYER_TYPES.LEARNOSITY_PLAYER),
      lessonPlayerShowing: (playerType === PLAYER_TYPES.LESSON_PLAYER),
      previewContentItemId: theAssignment.id,
      previewContentType: theAssignment.contentItemEntityTypeId,
      resourceName: theAssignment.contentItemName
    });
  }
}
