import React from 'react';
import { Icon } from 'semantic-ui-react';
import { TROPHY } from '../managers/ProgressManager';
import '../css/Trophy.less';

const Trophy = (props) => {
  let color;
  switch (props.color) {
    case TROPHY.GOLD: {
      color = '#eabf0a';
      break;
    }
    case TROPHY.SILVER: {
      color = '#7c7c7c';
      break;
    }
    case TROPHY.BRONZE: {
      color = '#b06c1b';
      break;
    }
    default: {
      color = TROPHY.NONE;
      break;
    }
  }

  return (
    <>
      {' '}
      {
      (color === TROPHY.NONE) ? (
        <div className='trophy-box'>
          <div className='empty' />
        </div>
      ) : (
        <div className='trophy-box'>
          <Icon name='trophy' style={{ color }} />
          <Icon name='star' style={{ color: '#fff' }} />
        </div>
      )
}
    </>
  );
};

export default Trophy;
