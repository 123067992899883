import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Segment } from 'semantic-ui-react';
import { TROPHY } from '../managers/ProgressManager';
import Trophy from './Trophy';
import StatusCard from './StatusCard';
import '../css/ProgressView.less';
import { NavigationService } from 'sat-core';

export default
@inject('progressManager', 'userManager', 'classroomManager')
@observer
class ProgressList extends Component {
  headerBar = () => (
    <Segment className='summary-legend'>
      <div className='title'>Result Overview</div>
      <div className='trophy-group'>
        <Trophy color={TROPHY.GOLD} />
        <div className='trophy-label'>Gold</div>
        <Trophy color={TROPHY.SILVER} />
        <div className='trophy-label'>Silver</div>
        <Trophy color={TROPHY.BRONZE} />
        <div className='trophy-label last'>Bronze</div>
      </div>
    </Segment>
  )

  handleClick = (courseId, courseResourceElementId, name) => {
    NavigationService.handleViewBenchmark(courseId, courseResourceElementId, name, this.props.history);
  }

  cardList = () => {
    const { progressManager } = this.props;
    const { classroomProgress } = progressManager;

    return (
      <>
        {classroomProgress.map((element) => <StatusCard key={element.courseResourceElementId} element={element} handleClick={this.handleClick} />
        )}
      </>
    );
  }

  render() {
    return (
      <div className='summary-list'>

        {this.headerBar()}
        {this.cardList()}
      </div>
    );
  }
}
