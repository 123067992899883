import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { CONTENT_ITEM_TYPES } from 'sat-core';
import ReactHtmlParser from 'react-html-parser';
import { Loader, Segment } from 'semantic-ui-react';
import ContentItemService from '../services/ContentItemService';
import '../css/FirstContentCard.less';
import BackgroundService from '../services/BackgroundService';

export default
@inject('contentManager')
@observer
class FirstContentCard extends Component {
  async componentDidMount() {
    await ContentItemService.getContentItem(this.props.courseElement.entityId);
  }

  render() {
    const { courseElement, contentManager } = this.props;
    const { resourceInfo } = courseElement;
    const resourceItem = contentManager.resourceItems.get(courseElement.entityId);
    const video = (courseElement.type === CONTENT_ITEM_TYPES.VIDEO_RESOURCE && resourceItem !== undefined);

    return (
      <>
        {(resourceItem === undefined) ?
          <Loader active /> : (
            <Segment key={`leaf_card_${courseElement.id}`} className='fcard-card-satcore list-card'>
              <div className={(video) ? 'card-body with-video' : 'card-body'}>
                <div className='fcard-name'>{ReactHtmlParser(courseElement.name)}</div>
                <div className='fcard-subtitle'>{ReactHtmlParser(courseElement.description)}</div>
                {(video) ?
                  <div className='fcard-description'>{ReactHtmlParser(resourceInfo)}</div> :
                  (resourceItem.articleHtml !== null && resourceItem.articleHtml !== undefined) ? <div className='fcard-description'>{ReactHtmlParser(resourceItem.articleHtml)}</div> : null}
              </div>
              {(video) ? (
                <div className='fcard-video'>
                  <video controls controlsList='nodownload' disablePictureInPicture onContextMenu={(e) => e.preventDefault()} width='360'><source src={ContentItemService.generateStreamUrl(resourceItem.id, CONTENT_ITEM_TYPES.VIDEO_RESOURCE)} type={resourceItem.mime} /></video>
                </div>
              ) : null}
            </Segment>
          )}
      </>
    );
  }
}
