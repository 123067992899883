import React from 'react';

const ZeroCourses = (props) => (
  <div className='zero-course' style={props.style}>
    <div className='zero-image' />
    {(props.noClass !== undefined) ? <div className='zero-text'>You aren’t part of any classes yet. Your teacher will enroll you soon.</div> :
      (props.noCourse !== undefined) ?
        <div className='zero-text'>You have not joined a classroom yet.</div> :
        <div className='zero-text'>You don’t have any benchmark activities yet</div>}
  </div>
);

export default ZeroCourses;
