import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { SatCoreComponent } from 'sat-core';

import '../css/App.less';

import BackgroundService from '../services/BackgroundService';

export default @inject(
  'backgroundImageManager'
)
@observer
class AppOverride extends Component {
  constructor(props) {
    super(props);
    this.App = SatCoreComponent('App');
    BackgroundService.initBackgroundImageService();
  }

  render() {
    const { backgroundImageManager } = this.props;
    const { App } = this;
    const { currentImageClass } = backgroundImageManager;
    const { currentImage } = backgroundImageManager;

    return (
      <>
        <div
          className={`background-panel ${currentImageClass}`}
          style={(currentImage !== null) ? { backgroundImage: `url(${currentImage})` } : null} />
        <App />
      </>
    );
  }
}
