import React, { Component } from 'react';
import { ClassRoster, SatCoreComponent, SatCoreRegister } from 'sat-core';
import { inject, observer } from 'mobx-react';

@inject('userManager', 'classroomManager')
@observer
class ClassRosterOverride extends Component {
  constructor(props) {
    super(props);
    this.StudentAccessCode = SatCoreComponent('StudentAccessCode');
  }

  // Override of this function to not include any co-teacher functionality, this
  // class should be removed later when we implement co-teachers for Empowered.
  renderAccessCodeContainer = () => {
    const { userManager } = this.props;
    const { StudentAccessCode } = this;
    return (
      <>
        {(!userManager.isSsoUser) && (
        <div className='accessCodeContainer'>
          {(userManager.isSsoUser) ? null : (
            <>
              <StudentAccessCode classId={this.props.classroomManager.currentClassroomId} />
            </>
          )}
        </div>
        )}
      </>
    );
  }

  render() {
    const { props } = this;
    return (
      <>
        <ClassRoster
          {...props}
          history={this.props.history}
          location={this.props.location}
          readOnly={true}
          renderAccessCodeContainer={this.renderAccessCodeContainer} />
      </>
    );
  }
}

SatCoreRegister('ClassRoster', ClassRosterOverride);
