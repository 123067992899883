import {
  CourseService,
  ELEMENT_TYPE,
  NavigationService,
  assignmentManager,
  classroomManager,
  courseManager,
  navigationManager,
  tagContentManager,
  userManager
} from 'sat-core';

import progressManager from '../managers/ProgressManager';

CourseService.loadStudentCourses = async () => {
  await courseManager.fetchClassroomCourses(classroomManager.currentClassroomId, false);
};

CourseService.fetchClassroomCourses = async (id) => {
  await courseManager.fetchClassroomCourses(id, false); // for empowered we need to always pass false
  // as 'isTeacher' because extra assignment should be hidden.
};

CourseService.initCourseData = async (loadData = true, force = false, rebuild = true) => {
  if ((force === true) ||
    (courseManager.currentCourseId && classroomManager.currentClassroomId && !courseManager.treeLoading &&
      (!courseManager.currentCourseElementList || !courseManager.currentCourseElementList.length))) {
    const elementId = (courseManager.currentElementId === 'ROOT') ? '' : courseManager.currentElementId;

    if (userManager.isTeacher || userManager.isCoTeacher) {
      await assignmentManager.makeActivityInstancesForUser(classroomManager.currentClassroomId, courseManager.currentCourseId);
    }

    const courseElements = classroomManager.currentClassroomId && await courseManager.fetchCourseData(
      courseManager.currentCourseId,
      classroomManager.currentClassroomId,
      elementId, force, userManager.isStudent);
    if (courseElements && !courseManager.currentElementId) {
      courseElements.forEach(async (unit, index) => {
        const elements = await courseManager.fetchCourseData(
          courseManager.currentCourseId,
          classroomManager.currentClassroomId,
          unit.elementId, false, userManager.isStudent, true
        );
        if (index === 1) {
          const sections = [];
          elements.forEach((element) => {
            sections.push((element.elementId));
          });
          await progressManager.fetchBenchmarkProgress(classroomManager.currentClassroomId, sections);
        }
      });
    } else {
      if (courseElements !== null && courseElements !== undefined) {
        const newContentIds = [];

        courseElements.forEach((item) => {
          newContentIds.push(item.contentItemId);
        });
        await tagContentManager.fetchTagsByCategoryAndContentItems(newContentIds, 'Required or Optional');
      }
    }

    if (loadData) {
      navigationManager.initSkipTypes([ELEMENT_TYPE.UNIT]);
      CourseService.loadData(rebuild);
    }

    if (userManager.isTeacher) {
      await classroomManager.fetchClassroomRoster(classroomManager.currentClassroomId);
    }
    return courseElements;
  }
  return courseManager.currentCourseElementList;
};

CourseService.loadData = (rebuild = true) => {
  const classroom = classroomManager.getClassroom(classroomManager.currentClassroomId);
  const course = courseManager.getCurrentCourse(classroomManager.currentClassroomId);
  const courseCount = courseManager.getClassroomCourseCount(classroomManager.currentClassroomId);
  if (rebuild === true) {
    NavigationService.buildReFreshPaths(
      classroom, courseCount,
      classroomManager.getClassName(classroomManager.currentClassroomId), course,
      courseManager.currentCourseElementList, courseManager, userManager.isStudent
    );
  }
};
