import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import '../css/GradebookActivity.less';
import { ASSIGNMENT_STATUS } from 'sat-core';

export default
@inject('gradebookManager')
@observer
class GradebookDataBar extends Component {
  getStatus = () => {
    const { currentAssignment } = this.props.gradebookManager;

    if (currentAssignment.status === ASSIGNMENT_STATUS.STARTED) {
      return 'Not Sent';
    }

    if (currentAssignment.status === ASSIGNMENT_STATUS.COMPLETED && currentAssignment.includeInReports) {
      return 'Approved!';
    }

    if (currentAssignment.status === ASSIGNMENT_STATUS.COMPLETED && !currentAssignment.includeInReports) {
      return 'Not Approved.';
    }

    if (currentAssignment.status === ASSIGNMENT_STATUS.CLOSED) {
      return `Awaiting Approval (Sent ${currentAssignment.fullTimezoneEndTime})`;
    }
  }

  render() {
    return (
      <div className='gradebook-data-content'>
        <div className='fg-col'>
          <div className='card-label'>LPL Status:</div>
          <div className='card-value'>{this.getStatus()}</div>
        </div>
      </div>
    );
  }
}
