/* eslint-disable indent */
import React, { Component } from 'react';
import { CourseLayout, ELEMENT_TYPE, SatCoreRegister } from 'sat-core';
import { inject, observer } from 'mobx-react';
import { Loader } from 'semantic-ui-react';
import RootLayout from './RootLayout';
import CourseContent from './CourseContent';
import '../css/CourseLayout.less';
import PlayerComponent from '../components/PlayerComponent';

@inject('courseManager', 'classroomManager', 'playerManager')
@observer
class CourseLayoutOverride extends Component {
  checkRoot=(root) => {
    if (root === null || root === undefined || root.trim() === '') {
      return true;
    }
    return false;
  }

  renderRest = () => {
    const { courseManager, classroomManager } = this.props;
    const { currentElementId, currentCourseElementList } = courseManager;

    if (currentCourseElementList.length > 1) {
      const teacherTrainingElement = currentCourseElementList[0];
      const benchMarkElement = currentCourseElementList[1];
      const tId = courseManager.currentCourseId + classroomManager.currentClassroomId + teacherTrainingElement.elementId;
      const teacherElements = courseManager.courseTreeMap.get(tId);

      const bId = courseManager.currentCourseId + classroomManager.currentClassroomId + benchMarkElement.elementId;
      const benchMarkElements = courseManager.courseTreeMap.get(bId);
      return (
        <div className='course-content'>
          {(currentElementId === ELEMENT_TYPE.ROOT || this.checkRoot(currentElementId)) ?
            ((teacherElements !== undefined && benchMarkElements !== undefined) ? (
              <RootLayout
                benchMarkElement={benchMarkElement}
                benchMarkElements={benchMarkElements}
                history={this.props.history}
                teacherElements={teacherElements}
                teacherTrainingElement={teacherTrainingElement} />
            ) : <Loader active />) :
            <CourseContent history={this.props.history} />}
        </div>
);
    } else if (currentCourseElementList.length === 1) {
      const element = currentCourseElementList[0];
      return (
        <div className='course-content'>
          {
            ((element !== undefined) ?
              <CourseContent history={this.props.history} />
              : <Loader active />)
          }
        </div>
);
    }
  };

  render() {
    const { props } = this;
    const { playerManager } = this.props;

    return (
      <>
        {(playerManager.playerShowing) ?
          <PlayerComponent /> :
          <CourseLayout {...props} renderRest={this.renderRest} />}
      </>
    );
  }
}

SatCoreRegister('CourseLayout', CourseLayoutOverride);
