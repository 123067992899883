import { action, computed, makeObservable, observable } from 'mobx';
import { Auth } from 'sat-core';

const PROGRESS_ENDPOINTS = {
  APPROVE_ACTIVITY: '/api/approveActivity',
  CLEAR_TO_LPL: '/api/clearLPLAdminSelection',
  DISAPPROVE_ACTIVITY: '/api/dontApproveActivity',
  GET_BENCHMARK_PROGRESS: '/api/getBenchmarkProgress',
  GET_CLASSROOM_PROGRESS: '/api/getClassroomProgress',
  GET_LEADERBOARD: '/api/viewReportOutput',
  SEND_TO_LPL: '/api/sendActivityInstanceToLPLAdmin',
  RE_OPEN_ACTIVITY: '/api/reopenStudentInstance'
};

export const TROPHY = {
  GOLD: 'GOLD',
  SILVER: 'SILVER',
  BRONZE: 'BRONZE',
  NONE: 'NONE'
};

export class ProgressManager {
  @observable benchmarkProgress = new Map();

  @observable classroomProgress = [];

  @observable leaderboard = {};

  @observable approvalStatusMessage = '';

  constructor() {
    makeObservable(this);
  }

  @action
  clearAll=() => {
    this.benchmarkProgress.clear();
    this.classroomProgress = [];
    this.leaderboard = {};
    this.approvalStatusMessage = '';
  }

  @action setBenchmarkProgress = (bmProgress) => {
    this.benchmarkProgress.set(bmProgress.courseResourceElementId, bmProgress);
  }

  @computed get speedometerObject() {
    const total = this.classroomProgress.length;
    let completed = 0;

    this.classroomProgress.forEach((item) => {
      if (item.trophy !== TROPHY.NONE) {
        ++completed;
      }
    });

    return {
      completed,
      total
    };
  }

  @action setLeaderboard = (lb) => {
    this.leaderboard = lb;
  }

  @action setClassroomProgress = (data) => {
    if (data === null || data === undefined) {
      this.classroomProgress.replace([]);
    } else {
      this.classroomProgress.replace(data);
    }
  }

  @action clearApprovalStatusMessage = () => {
    this.approvalStatusMessage = '';
  }

  openActivity = async (activityInstanceId) => {
    try {
      const body = {
        activityInstanceId
      };
      const response = await Auth.fetch(`${Auth.ecms}${PROGRESS_ENDPOINTS.RE_OPEN_ACTIVITY}?activityInstanceId=${activityInstanceId}`, {
        method: 'POST',
        body
      });
      if (response && response.status === 'SUCCESS') {
        return true;
      }
      return false;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  fetchClassroomProgress = async (classroomId) => {
    try {
      const response = await Auth.fetch(`${Auth.ecms}${PROGRESS_ENDPOINTS.GET_CLASSROOM_PROGRESS}?classroomId=${classroomId}`, {
        method: 'GET'
      });
      if (response && response.status === 'SUCCESS') {
        this.setClassroomProgress(response.data);
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  fetchBenchmarkProgress = async (classroomId, ids) => {
    try {
      const response = await Auth.fetch(`${Auth.ecms}${PROGRESS_ENDPOINTS.GET_BENCHMARK_PROGRESS}?classroomId=${classroomId}&courseResourceElementIds=${ids}`, {
        method: 'GET'
      });
      if (response && response.status === 'SUCCESS') {
        this.benchmarkProgress.clear();
        response.data.forEach((bmProgress) => {
          this.setBenchmarkProgress(bmProgress);
        });
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  sendToLPLAdmin = async (activityInstanceId) => {
    const body = {
      activityInstanceId
    };

    try {
      const response = await Auth.fetch(`${Auth.ecms}${PROGRESS_ENDPOINTS.SEND_TO_LPL}`, {
        method: 'POST',
        body
      });
      if (response && response.status === 'SUCCESS') {
        return true;
      }
      return false;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  fetchLeaderboard = async (institutionId, courseId) => {
    try {
      const entityId = institutionId + courseId;
      const response = await Auth.fetch(`${Auth.ecms}${PROGRESS_ENDPOINTS.GET_LEADERBOARD}?entityId=${entityId}&reportId=EMPOWERED_LEADERBOARD`, {
        method: 'GET'
      });
      if (response && response.status === 'SUCCESS') {
        if (response.data.length > 0) {
          // console.info(response.data);
          this.setLeaderboard(response.data[0].contentJson);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  @computed get topThreeSchools() {
    const { leaderboard } = this.leaderboard;
    const topThree = [];
    if (leaderboard === null || leaderboard === undefined) {
      return topThree;
    }
    for (let i = 0; i < leaderboard.length; i++) {
      topThree.push(leaderboard[i]);
      if (i === 3) {
        break;
      }
    }
    return topThree;
  }

  @computed get focusedList() {
    const { leaderboard } = this.leaderboard;
    const { institutionId } = this.leaderboard;

    let focusList = [];

    if (leaderboard === null || leaderboard === undefined) {
      return focusList;
    }

    const targetIndex = leaderboard.findIndex((element) => element.id === institutionId);
    const before = targetIndex - 1;
    let after = targetIndex + 1;

    if (before >= 0 && after < leaderboard.length) {
      focusList.push(leaderboard[before]);
      focusList.push(leaderboard[targetIndex]);
      focusList.push(leaderboard[after]);
    } else if (targetIndex >= 0 && targetIndex <= 2) {
      for (let i = 0; i < leaderboard.length; ++i) {
        focusList.push(leaderboard[i]);
        if (focusList.length === 3) {
          break;
        }
      }
    } else if (targetIndex <= leaderboard.length - 1 && targetIndex >= leaderboard.length - 3) {
      // eslint-disable-next-line for-direction
      for (let i = leaderboard.length - 1; i >= 0; --i) {
        focusList.push(leaderboard[i]);
        if (focusList.length === 3) {
          break;
        }
      }
      focusList = focusList.reverse();
    }

    if (after < leaderboard.length) {
      // noop?
    } else {
      after = targetIndex - 2;
    }

    if (focusList.length < 3 && before < leaderboard.length) {
      focusList.push(leaderboard[before]);
    }

    if (focusList.length < 3 && after >= 0) {
      focusList.push(leaderboard[after]);
    }

    return focusList;
  }

  approveActivity = async (activity, note) => {
    const body = {
      activityId: activity.id,
      comments: note
    };

    this.approvalStatusMessage = '';

    try {
      const response = await Auth.fetch(`${Auth.ecms}${PROGRESS_ENDPOINTS.APPROVE_ACTIVITY}`, {
        method: 'POST',
        body
      });
      if (response && response.status === 'SUCCESS') {
        return true;
      }
      if (response && response.statusMessage === 'notScored') {
        this.approvalStatusMessage = 'Activity not yet scored, or the score is zero. If you wish to approve this activity the score cannot be zero.';
        return false;
      }
      this.approvalStatusMessage = 'There was an error approving this activity.';
      return false;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  disApproveActivity = async (activity, note) => {
    const body = {
      activityId: activity.id,
      comments: note
    };

    try {
      const response = await Auth.fetch(`${Auth.ecms}${PROGRESS_ENDPOINTS.DISAPPROVE_ACTIVITY}`, {
        method: 'POST',
        body
      });
      if (response && response.status === 'SUCCESS') {
        return true;
      }
      return false;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  clearToLPLAdmin = async (activityInstanceId) => {
    const body = {
      activityInstanceId
    };

    try {
      const response = await Auth.fetch(`${Auth.ecms}${PROGRESS_ENDPOINTS.CLEAR_TO_LPL}`, {
        method: 'POST',
        body
      });
      if (response && response.status === 'SUCCESS') {
        return true;
      }
      return false;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}

export default new ProgressManager();
