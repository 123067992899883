import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Container, Header, Icon, List, Loader } from 'semantic-ui-react';

import '../../css/LPLReportsView.less';

import {
  PATH_TYPES,
  SatCoreComponent,
  SatCoreRegister,
  VIEW_SELECTION,
  navigationManager
} from 'sat-core';

export default @inject('reportsManager')
@observer
class LPLReportsView extends Component {
  constructor(props) {
    super(props);
    this.StateIconComponent = SatCoreComponent('StateIconComponent');
  }

  async componentDidMount() {
    const { reportsManager } = this.props;
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('view')) {
      navigationManager.setView(urlParams.get('view'));
    } else {
      navigationManager.setView(VIEW_SELECTION.REPORTS);
    }

    navigationManager.clearAllPaths();

    navigationManager.addPath({
      currentCourseId: null,
      currentClassroomId: null,
      currentElementId: null,
      name: 'Reports',
      type: PATH_TYPES.LINK,
      treeNavigationFunction: null // only valid if type
    });
    await reportsManager.fetchLPLReportsViewData();
  }

  async handleViewReport(courseId, reportEntityId, courseName) {
    console.log(`go to report: ${courseId}`);// remove
    await this.props.history.push(`/reports/class-performance-report?reportEntityId=${reportEntityId}&courseId=${courseId}&courseName=${encodeURIComponent(courseName)}&view=${VIEW_SELECTION.CLASS_PERFORMANCE}`);
  }

  // Base the icon on the user's institution state, show default if icon not found.
  getStateIcon = (stateCode) => {
    const { StateIconComponent } = this;
    if (stateCode) {
      return <StateIconComponent key={stateCode} institutionState={stateCode} />;
    }
  }

  renderReportCard = (stateCode, courses) => {
    let stateName = '';
    let stateIcon = '';
    const coursesList = [];
    if (courses && courses.length > 0) {
      stateName = courses[0].stateName;
      stateIcon = this.getStateIcon(stateCode);
      courses.map((course) => {
        if (course.courseId) {
          coursesList.push(
            <List.Item key={`course_${stateCode}_${course.courseId}`}>
              <List.Content>
                <List.Description>
                  <div className='course-name'>{course.courseName}</div>
                  <div className='open-report' onClick={() => this.handleViewReport(course.courseId, course.reportEntityId, course.courseName)}>
                    <div className='open-report-label'>OPEN</div>
                    <div className='open-report-arrow-container'><Icon className='open-report-arrow' name='right arrow' /></div>
                  </div>
                </List.Description>
              </List.Content>
            </List.Item>
          );
        }
        return true;
      });
    }
    return (
      <div key={stateName || `temp-key-${Math.random()}`} className='report-card'>
        <div className='report-card-header'>
          <div className='report-card-title'>
            {stateName}
          </div>
          <div className='report-card-icon'>
            {stateIcon}
          </div>
        </div>
        <div className='report-card-classes'>
          {(coursesList.length > 0) ? (
            <List>
              {coursesList}
            </List>
          ) : <div className='null-state-message'>Add a class to view this report</div>}
        </div>
      </div>
    );
  }

  render() {
    const { reportsManager } = this.props;
    const { lplClassReportViewData, lplClassReportViewDataLoaded } = reportsManager;
    const reportCards = [];

    if (lplClassReportViewData) {
      Object.keys(lplClassReportViewData).forEach(function (stateCode) {
        const courses = lplClassReportViewData[stateCode];
        if (courses && courses.length) {
          reportCards.push(this.renderReportCard(stateCode, courses));
        }
      }, this);
    }

    return (
      <Container className='reports-view' fluid>
        <div className='headerContainer'>
          <div className='headerWrapper'>
            <Header as='h1'>Reports</Header>
          </div>
        </div>
        <div className='empowerReportContainer'>
          <div className='report-card-row'>
            <Loader key={0} active={!lplClassReportViewDataLoaded} />
            {lplClassReportViewDataLoaded && reportCards}
          </div>
        </div>
      </Container>
    );
  }
}

SatCoreRegister('LPLReportsView', LPLReportsView);
