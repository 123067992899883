import React from 'react';
import { TROPHY } from '../managers/ProgressManager';
import '../css/Statusbar.less';

const drawTics = (number) => {
  const tics = [];
  const time = new Date();
  const key = time.getTime();

  for (let i = 0; i < number; i++) {
    tics.push(<div key={`${key}_${i}`} className='tic' />);
  }

  return tics;
};

const Statusbar = (props) => {
  let color;
  switch (props.element.trophy) {
    case TROPHY.GOLD: {
      color = '#eabf0a';
      break;
    }
    case TROPHY.SILVER: {
      color = '#7c7c7c';
      break;
    }
    case TROPHY.BRONZE: {
      color = '#b06c1b';
      break;
    }
    default: {
      color = '#25225e';
      break;
    }
  }

  const width = '100%';
  const progressWidth = `${(props.element.completedActivities / props.element.totalActivities) * 100}%`;

  let style = { width: progressWidth, backgroundColor: color };
  if (progressWidth === '100%') {
    style = { width: progressWidth, backgroundColor: color, borderBottomRightRadius: '20px', borderTopRightRadius: '20px' };
  }

  return (
    <div className='bar' style={{ width }}>
      <div className='tic-marks' style={{ width }}>
        {drawTics(props.element.totalActivities - 1)}
      </div>
      <div className='progress' style={style} />
    </div>
  );
};

export default Statusbar;
