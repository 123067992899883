import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Button, Form, Grid, Message } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import { SatCoreComponent, SatCoreRegister, Modal, UserService } from 'sat-core';

const INSTITUTION_DROPDOWN_MODE = 'addClassroomMode';

export default
@inject('adminUsersManager', 'classroomManager', 'userManager')
@observer
class AddClassModalOverride extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.modalFormItems = ['classroomName', 'institution', 'startDate'];
    this.ModalBanner = SatCoreComponent('ModalBanner');
    this.UserInstitutionDropdown = SatCoreComponent('UserInstitutionDropdown');
  }

  getInitialState = () => {
    const initialState = {
      classroomName: {
        error: false,
        errorMessage: '',
        value: ''
      },
      institution: {
        error: false,
        errorMessage: '',
        value: ''
      },
      loading: false,
      pacing: 'OPEN', // defaulting this to OPEN per DEMO-22
      pacingError: false,
      serverError: false,
      serverErrorMsg: '',
      startDate: {
        error: false,
        errorMessage: '',
        value: ''
      }
    };
    return initialState;
  }

  handleChange = (_event, { name, value }) => {
    this.setState({
      [name]: {
        error: false,
        errorMessage: '',
        value
      }
    });
  }

  handleCancel = (event, data) => {
    const { close, userManager } = this.props;
    const managerName = userManager.isDistrictOrSchoolAdmin ? 'adminUsersManager' : 'userManager';
    const hasMultipleInstitutions = UserService.hasMultipleInstitutions(managerName);
    if (hasMultipleInstitutions) {
      const { SELECT_CLASSROOM_MODE } = INSTITUTION_DROPDOWN_MODE;
      const institutionId = UserService.getSelectedDropdownInstitutionId(SELECT_CLASSROOM_MODE);
      UserService.setSelectedDropdownInstitutionId(institutionId);
    }
    close(event, data);
  }

  handleSubmit = async (event, data) => {
    this.setState({ loading: true });
    const { adminUsersManager, close, t, userId, userManager } = this.props;
    const { classroomName, startDate, pacing } = this.state;

    const managerName = userManager.isDistrictOrSchoolAdmin ? 'adminUsersManager' : 'userManager';
    const hasMultipleInstitutions = UserService.hasMultipleInstitutions(managerName);

    const validated = await this.validate();
    if (!validated) {
      this.setState({ loading: false });
      return;
    }
    const { ADD_CLASSROOM_MODE } = INSTITUTION_DROPDOWN_MODE;
    let institutionId;

    if (hasMultipleInstitutions) {
      institutionId = UserService.getSelectedDropdownInstitutionId(ADD_CLASSROOM_MODE);
    } else {
      if (managerName === 'userManager') {
        institutionId = userManager.institutionId;
      } else {
        institutionId = adminUsersManager.currentUser?.institutionId;
      }
    }
    const startDateDate = new Date(Date.parse(startDate.value));
    const year = startDateDate.getFullYear();
    const month = 7; //august
    const day = 1;

    let endDateDate = null;
    if (  (startDateDate.getMonth() >= 0 &&  startDateDate.getMonth() <= 5)) {
      endDateDate  =  new Date(year, month, day);
    } else {
      endDateDate = new Date(year + 1, month, day);
    }
    const endDate = endDateDate.toLocaleDateString();

    const response = await this.createClassroom({
      classroomName: classroomName.value,
      endDate: endDate,
      institutionId,
      pacing,
      startDate: startDate.value,
      teacherId: userId
    });
    if (response?.status === 'SUCCESS') {
      this.setState({ loading: false });
      close(event, data, response, institutionId);
    } else {
      const serverErrorMsg = response?.statusMessage || t('defaultServerErrorMsg');
      this.setState({ loading: false, serverError: true, serverErrorMsg });
    }
  }

  validate = async () => {
    const { userManager } = this.props;
    const { classroomName, startDate } = this.state;
    let hasError = false;

    this.modalFormItems.forEach((itemName) => {
      switch (itemName) {
        case 'classroomName':
          if (!classroomName.value.trim() || classroomName.value.trim() === '') {
            this.setError(itemName, 'Name is required');
            hasError = true;
          } else if (classroomName.value.length >= 100) {
            this.setError(itemName, 'Name length should less than 100 characters');
            hasError = true;
          }
          break;
        case 'institution':
          const managerName = userManager.isDistrictOrSchoolAdmin ? 'adminUsersManager' : 'userManager';
          const hasMultipleInstitutions = UserService.hasMultipleInstitutions(managerName);
          const { ADD_CLASSROOM_MODE } = INSTITUTION_DROPDOWN_MODE;
          const selectedDropdownInstitutionId = UserService.getSelectedDropdownInstitutionId(ADD_CLASSROOM_MODE);
          if (hasMultipleInstitutions && (!selectedDropdownInstitutionId || selectedDropdownInstitutionId === 'allInstitutions')) {
            this.setError(itemName, 'Institution is required');
            hasError = true;
          }
          break;
        case 'startDate':
          if (startDate.value === null || startDate.value === '') {
            this.setError(itemName, 'Start Date is required');
            hasError = true;
          }
          break;
        default:
      }
    });
    return !hasError;
  }

  setError = (name, message = null) => {
    this.setState((prevState) => {
      const newState = { ...prevState };
      newState[name].error = true;
      message && (newState[name].errorMessage = message);
      return newState;
    });
  }

  createClassroom = async ({ endDate,
                             institutionId,
                             classroomName,
                             pacing,
                             startDate,
                             teacherId
                           } = {}) => {
    const { classroomManager } = this.props;
    if (teacherId) {
      /** currently used by admins to create classrooms for other users (i.e. teachers) */
      return classroomManager.createClassroomForTeacher(
        teacherId, classroomName, startDate, endDate, pacing, institutionId
      );
    } else {
      /** currently used by logged in teacher to self-create classrooms */
      return classroomManager.createClassroomForUser(
        classroomName, startDate, endDate, pacing, institutionId
      );
    }
  }

  renderClassroomNameFormField = () => {
    const { t } = this.props;
    const { loading, classroomName } = this.state;
    return (
      <Form.Field disabled={loading}>
        <Form.Input
          label={t('classroomName')}
          name='classroomName'
          onChange={this.handleChange}
          placeholder={t('classroomName')}
          type='text'
          value={classroomName.value} />
        <Message content={classroomName.errorMessage} error visible={classroomName.error} />
      </Form.Field>
    );
  }

  renderSelectInstitutionFormField = () => {
    const { userManager } = this.props;
    const { institution, loading } = this.state;
    const { ADD_CLASSROOM_MODE } = INSTITUTION_DROPDOWN_MODE;
    const { UserInstitutionDropdown } = this;
    const managerName = userManager.isDistrictOrSchoolAdmin ? 'adminUsersManager' : 'userManager';
    return (
      <Form.Field disabled={loading}>
        <UserInstitutionDropdown {...this.props}
                                 managerName={managerName}
                                 mode={ADD_CLASSROOM_MODE}
                                 onChange={(event, data) => {
                                   return this.handleChange(event, {
                                     name: 'institution',
                                     value: data?.value
                                   });
                                 }}
                                 shouldOnlyUpdateCurrentDropdownMode={true} />
        <Message content={institution.errorMessage} error visible={institution.error} />
      </Form.Field>
    );
  }

  renderStartDateFormField = () => {
    const { t } = this.props;
    const { loading, startDate } = this.state;
    return (
      <Form.Field disabled={loading}>
        <DateInput
          animation='false'
          dateFormat='MM/DD/YYYY'
          duration={0}
          iconPosition='right'
          label={t('startDate')}
          name='startDate'
          onChange={this.handleChange}
          placeholder={t('startDate')}
          value={startDate.value} />
        <Message content={startDate.errorMessage} error visible={startDate.error} />
      </Form.Field>
    );
  }


  render() {
    const { ModalBanner } = this;
    const { open, close, t } = this.props;
    const { loading, serverError, serverErrorMsg } = this.state;
    return (
      <>
        <Modal
          className='addClassModal'
          closeOnDimmerClick={false}
          closeOnEscape={true}
          onClose={close}
          open={open}>
          <ModalBanner onClose={close} title={t('addClassTitle', 'Add Class')} />
          <Modal.Content>
            <Grid className='fullHeight element-body' textAlign='center' verticalAlign='middle'>
              <Grid.Column>
                <Form>
                  {/* CLASSROOM NAME */}
                  {this.renderClassroomNameFormField()}
                  {/* SELECT INSTITUTION */}
                  {this.renderSelectInstitutionFormField()}
                  {/* START DATE */}
                  {this.renderStartDateFormField()}

                </Form>
                {serverError ? (
                  <div className='server-error-msg'>
                    <Message content={serverErrorMsg} error />
                  </div>
                ) : null}
              </Grid.Column>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Button basic className='cancelButton'
                    disabled={loading} onClick={this.handleCancel} primary>
              {t('cancel')}
            </Button>
            <Button className='ui primary button saveButton'
                    disabled={loading} loading={loading}
                    onClick={this.handleSubmit}>
              {t('save')}
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

SatCoreRegister('AddClassModal', AddClassModalOverride);
