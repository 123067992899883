import {
  CourseService,
  InitService,
  classroomManager,
  courseManager,
  getSessionStorageItem,
  navigationManager,
  notificationManager,
  removeSessionStorageItem,
  userManager
} from 'sat-core';

InitService.initData = async (urlParams) => {
  let elementId = '';

  if (urlParams.has('classroomId')) {
    classroomManager.setCurrentClassroomId(urlParams.get('classroomId'));
  }

  if (urlParams.has('courseId')) {
    courseManager.setCurrentCourseId(urlParams.get('courseId'));
  }

  if (urlParams.has('elementId')) {
    elementId = urlParams.get('elementId');
    courseManager.setCurrentElementId(elementId);
  }
  await userManager.checkUser();
  navigationManager.setIsStudent(userManager.isStudent);
  await classroomManager.fetchClassroomData(classroomManager.currentClassroomId, 0, userManager.isStudent, userManager.userId);

  if (userManager.isStudent && classroomManager.currentClassroomId !== null) {
    await CourseService.fetchClassroomCourses(classroomManager.currentClassroomId, false);
    await CourseService.initCourseData(true, true, urlParams.get('view') !== 'workspace');
  }

  if (!userManager.isStudent) {
    await notificationManager.fetchManualNotifications({
      page: 0,
      userId: userManager.userId
    });
    await notificationManager.fetchSystemNotifications({
      page: 0,
      userId: userManager.userId
    });
  }

  const notificationId = getSessionStorageItem('c2c_notificationId');
  if (notificationId) {
    removeSessionStorageItem('c2c_notificationId');
    await notificationManager.fetchSystemNotification(notificationId);
    InitService.viewNotificationModal(notificationId);
  }
};
