import React, { Component } from 'react';
import { Login, SatCoreComponent, SatCoreRegister } from 'sat-core';

import '../css/Login.less';

export default class LoginOverride extends Component {
  constructor(props) {
    super(props);
    this.Logo = SatCoreComponent('Logo');
  }

  render() {
    const { Logo } = this;
    return (
      <div className='login-banner'>
        <div className='login-logo-wrapper'>
          <Logo />
        </div>
        <div className='login-welcome'>
          <h1>Welcome to emPowered Schools</h1>
        </div>
        <div className='login-welcome-subheader' />
        <Login {...this.props} />
      </div>
    );
  }
}

SatCoreRegister('Login', LoginOverride);
