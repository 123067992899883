import React, { Component } from 'react';
import { ImageService, PATH_TYPES, SatCoreComponent, VIEW_SELECTION } from 'sat-core';
import { inject, observer } from 'mobx-react';
import ReactHtmlParser from 'react-html-parser';
import { Container, Loader } from 'semantic-ui-react';
import '../css/GradebookActivity.less';
import GradebookCard from '../components/GradebookCard';
import GradebookDataBar from '../components/GradebookDataBar';
import PlayerComponent from '../components/PlayerComponent';

export default
@inject('gradebookManager', 'navigationManager', 'assignmentManager', 'userManager', 'playerManager')
@observer
class Gradebook extends Component {
  constructor(props) {
    super(props);
    this.BreadCrumbs = SatCoreComponent('BreadCrumbs');
    this.GradebookActivityTable = SatCoreComponent('GradebookActivityTable');
    this.state = {
      showAnswers: false
    };
  }

  async componentDidMount() {
    const { gradebookManager, navigationManager, assignmentManager } = this.props;
    const urlParams = new URLSearchParams(this.props.location.search);
    navigationManager.setView(VIEW_SELECTION.GRADEBOOK);
    if (urlParams.has('assignmentId')) {
      const assignment = await gradebookManager.fetchGradebookData(urlParams.get('assignmentId'));
      if (assignment != null) {
        assignmentManager.setAssignment(assignment);

        if (urlParams.has('fromAssignments')) {
          navigationManager.clearSetAssignments();
        }

        navigationManager.addPath({
          currentCourseId: null,
          currentClassroomId: null,
          currentElementId: null,
          name: 'Details',
          type: PATH_TYPES.LINK,
          treeNavigationFunction: null // only valid if type
        });

        this.state.contentName = (assignment.resourceWebTitle !== '' && assignment.resourceWebTitle != null) ? assignment.resourceWebTitle : assignment.name;
        this.state.instruction = assignment.instruction;
        this.state.contentImageUrl = ImageService.getImageUrl(assignment);
        this.state.resourceName = assignment.name;
      }
    }
  }

  render() {
    const { gradebookManager, playerManager } = this.props;
    const { currentAssignment, currentAssignmentId } = gradebookManager;

    const { BreadCrumbs } = this;
    const { GradebookActivityTable } = this;

    if (playerManager.playerShowing) {
      return (<PlayerComponent />);
    }

    return (

      <Container className='grade-book-activity-view' fluid>
        <BreadCrumbs history={this.props.history} />
        {currentAssignmentId === null ?
          <Loader active /> : (
            <div className='grade-book-layout'>
              <div className='left-col'>
                <GradebookCard history={this.props.history} />
              </div>
              <div className='right-col'>
                <div className='banner-row'>
                  <h1 className='header'>
                    {(currentAssignment.resourceWebTitle !== '' && currentAssignment.resourceWebTitle != null) ?
                      ReactHtmlParser(currentAssignment.resourceWebTitle) : ReactHtmlParser(currentAssignment.name)}
                  </h1>
                </div>
                <GradebookDataBar />
                <GradebookActivityTable location={this.props.location} />
              </div>
            </div>
          )}
      </Container>
    );
  }
}
