import React, { Component } from 'react';

// Import react-circular-progressbar module and styles
import {
  CircularProgressbarWithChildren,
  buildStyles
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import '../css/Speedometer.less';

// Radial separators
import { Segment } from 'semantic-ui-react';
import RadialLabel from './RadialLabel';

export default class Speedometer extends Component {
  render() {
    const { complete, total } = this.props;

    return (
      <Segment className='speedometer'>
        <CircularProgressbarWithChildren
          circleRatio={0.5}
          strokeWidth={12}
          styles={buildStyles({
            rotation: 0.75,
            strokeLinecap: 'butt',
            trailColor: '#eee',
            pathColor: '#f7a11a'
          })}
          value={(complete / total) * 100}>
          <RadialLabel
            count={total}
            style={{
              background: '#000',
              width: '2px',
              // This needs to be equal to props.strokeWidth
              height: `${10}%`
            }} />
        </CircularProgressbarWithChildren>
      </Segment>
    );
  }
}
