import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Loader } from 'semantic-ui-react';
import { ClassDetails, CourseService, SatCoreRegister, VIEW_SELECTION } from 'sat-core';
import '../css/ClassDetail.less';
import ZeroCourses from '../components/ZeroCourses';

//
@inject('classroomManager', 'userManager', 'courseManager', 'navigationManager')
@observer
class ClassDetailOverride extends Component {
  constructor(props) {
    super(props);
    props.courseManager.setTreeLoading(true);
  }

  async componentDidMount() {
    const { classroomManager, courseManager, navigationManager } = this.props;

    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('view')) {
      navigationManager.setView(urlParams.get('view'));
    } else {
      navigationManager.setView(VIEW_SELECTION.BOOK);
    }

    if (urlParams.has('classroomId')) {
      if (classroomManager.currentClassroomId === null) {
        classroomManager.setCurrentClassroomId(urlParams.get('classroomId'));
        courseManager.setTreeLoading(true);
      }
    }

    if (urlParams.has('courseId')) {
      if (courseManager.currentCourseId === null) {
        courseManager.setCurrentCourseId(urlParams.get('courseId'));
        courseManager.setTreeLoading(true);
      }
    }
    await CourseService.initCourseData(true, true, true);
  }

  render() {
    const { userManager, classroomManager, courseManager } = this.props;

    const showLoading = (courseManager.isTreeLoading || classroomManager.isLoading);
    const showStudentZero = ((showLoading === false) && (userManager.isStudent && (classroomManager.currentClassroomId === null || courseManager.currentCourseId === null)));
    const showCourseTree = (showLoading === false && showStudentZero === false);

    return (
      <>
        <Loader active={true} style={{ display: (showLoading) ? 'block' : 'none' }} />
        {(userManager.isSsoUser) ? <ZeroCourses noClass={true} style={{ display: (showStudentZero) ? 'flex' : 'none' }} /> :
        <ZeroCourses noCourse={true} style={{ display: (showStudentZero) ? 'flex' : 'none' }} />}
        <ClassDetails {...this.props} style={{ display: (showCourseTree) ? 'block' : 'none' }} zerostate={<ZeroCourses noCourse={true} style={{ display: (showCourseTree) ? 'flex' : 'none' }} />} />
      </>
    );
  }
}

SatCoreRegister('ClassDetails', ClassDetailOverride);
