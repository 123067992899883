import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { Modal } from 'sat-core';
import { inject, observer } from 'mobx-react';

import '../css/ProgressView.less';

export default
@inject('progressManager')
@observer
class LeaderBoardModal extends Component {
  tableHeader = (t1, t2) => (
    <div className='table-header'>
      <div className='table-title'>{t1}</div>
      <div className='table-title right'>{t2}</div>
    </div>
  )

  rankList = () => {
    const { progressManager } = this.props;

    const rankList = progressManager.leaderboard.leaderboard;

    if (rankList === undefined || rankList === null) {
      return;
    }
    return (
      <table className='table'>
        <colgroup>
          <col width='7%' />
          <col width='78%' />
          <col width='10%' />
        </colgroup>
        <tbody>
          {rankList.map((item) => ((item !== undefined) ? (
            <tr key={`rr_${item.id}`} className='table-row'>
              <td className='inst-rank'>{item.rank}</td>
              <td className='inst-name bottom'>{item.name}</td>
              <td className='score'>{(item.points !== undefined) ? item.points : 0}</td>
            </tr>
          ) : null))}
        </tbody>
      </table>
    );
  }

  render() {
    const { openModal, closeModal } = this.props;
    return (
      <Modal
        className='progress-view'
        open={openModal}
        scroll
        size='tiny'>
        <Modal.Header>Leaderboard</Modal.Header>
        <Modal.Content scrolling>
          <div className='table-wrapper'>
            {this.tableHeader('All Schools', 'Points')}
            {this.rankList()}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button basic content='Close' onClick={() => { closeModal(); }} primary />
        </Modal.Actions>
      </Modal>
    );
  }
}
