import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { CourseService, Modal, SatCoreComponent, SatCoreRegister } from 'sat-core';
import { Button, Container, Form, Header } from 'semantic-ui-react';

import '../../css/AddCourseModal.less';

@inject('classroomManager', 'courseManager', 'navigationManager', 'userManager', 'assignmentManager')
@observer
class AddCourseToClassroomRadio extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.SCCheckbox = SatCoreComponent('SCCheckbox');
    this.SatCoreLoader = SatCoreComponent('SatCoreLoader');
    this.CourseCard = SatCoreComponent('CourseCard');
  }

  componentDidMount = async () => {
    await this.refreshAllCourseData();
  }

  getInitialState = () => ({
    serverError: false,
    serverErrorMsg: '',
    loading: false
  })

  close = async (didWeAddCourses = false) => {
    const { courseManager, closeAddCourseToClassModal } = this.props;
    this.setState(this.getInitialState());
    closeAddCourseToClassModal(didWeAddCourses);
    courseManager.clearProductCourses();
    courseManager.clearSelectedNotAttachedProductCourses();
    await this.refreshAllCourseData();
  }

  submitAddSelectedCoursesToClass = async () => {
    const { loading } = this.state;
    if (!loading) {
      this.setState({ loading: true });
      await this.saveResults();
    }
    return false;
  }

  saveResults = async () => {
    const { courseManager } = this.props;
    if (courseManager.selectedNotAttachedProductCourseIds.length) {
      await this.doSaveThenRefresh();
    } else {
      this.setState({ loading: false });
      await this.close();
    }
  }

  doSaveThenRefresh = async () => {
    const { classroomId, courseManager, assignmentManager } = this.props;
    const result = await courseManager.addCoursesToClassroom(
      classroomId, courseManager.selectedNotAttachedProductCourseIds
    );
    if (!result) {
      alert('A recently deleted course cannot be added to this class. It will disappear from the list soon.');
      this.setState({ loading: false });
    } else {
      if (courseManager.selectedNotAttachedProductCourseIds.length > 0) {
        courseManager.selectedNotAttachedProductCourseIds.forEach(async (courseId) => {
          await assignmentManager.creatAllAssignmentsForCourse(classroomId, courseId);
        });
      }
      await this.doRefresh();
    }
  }

  doRefresh = async () => {
    const { classroomId, classroomManager, userManager } = this.props;
    try {
      await classroomManager.fetchClassroomData(classroomId);
      CourseService.fetchClassroomCourses(classroomId, userManager.isTeacher, userManager.isStudent);
      await this.close(true);
    } catch (error) {
      console.error(error);
      this.setState({
        serverError: true,
        serverErrorMsg: 'Something went wrong when refreshing courses, please try again'
      });
    }
  }

  refreshAllCourseData = async () => {
    const { classroomId, courseManager, history, navigationManager } = this.props;
    this.setState({ loading: true });
    courseManager.setCoursesLoading(true);
    courseManager.clearProductCourses();
    await courseManager.fetchUserCourseList(classroomId);
    navigationManager.navigateToClassroom(classroomId, history, true);
    courseManager.setCoursesLoading(false);
    this.setState({ loading: false });
  }

  handleCheck = (course) => {
    const { courseManager } = this.props;
    courseManager.setProductCourseSelected(course.id);
  }

  getItems = () => {
    const { courseManager, userManager } = this.props;

    const { SCCheckbox } = this;
    const { CourseCard } = this;
    const items = [];
    courseManager.productCourseList.map((pCourse, index) => { // eslint-disable-line no-unused-vars
      items.push(
        <Form.Field key={`${pCourse.id}_FF`} className='add-course-field'>
          <SCCheckbox
            key={pCourse.id}
            checked={pCourse.selected}
            disabled={pCourse.attached}
            onChange={() => { this.handleCheck(pCourse); }} />
          <CourseCard
            authKey={userManager.authKey}
            course={pCourse}
            handleCardClick={() => { this.handleCheck(pCourse); }}
            imageUrl={pCourse.imageUrl} />
        </Form.Field>);
      return true;
    });
    return items;
  }

  render() {
    const { displayAddCourseToClassModal, courseManager } = this.props;
    const { loading } = this.props;
    const { SatCoreLoader } = this;
    return (
      <>
        <Modal
          className='AddCourseToClassModal'
          closeOnDimmerClick={false}
          closeOnEscape={true}
          onClose={this.close}
          open={displayAddCourseToClassModal}>
          {courseManager.productCourseList.length < 1 ?
            <Modal.Content><SatCoreLoader /></Modal.Content> : (
              <>
                <Modal.Header className='modal-header'>
                  <Header.Content className='modal-header-bar'>
                    <span className='modal-header-label'>Select a Course</span>
                    <Button basic icon='close' onClick={this.close} size='huge' />
                  </Header.Content>
                </Modal.Header>
                <Modal.Content scrolling>
                  <Container className='class-course-container' fluid>
                    <Container className='class-course-list-container' fluid>
                      {this.getItems()}
                    </Container>
                  </Container>
                </Modal.Content>
                <Modal.Actions>
                  <Button basic className='cancelButton' onClick={this.close} primary>Cancel</Button>
                  <Button className='ui primary button saveButton' loading={loading} onClick={this.submitAddSelectedCoursesToClass}>Save</Button>
                </Modal.Actions>
              </>
            )}
        </Modal>
      </>
    );
  }
}

SatCoreRegister('AddCourseToClassModal', AddCourseToClassroomRadio);
